// We are not using this now will be deletd in future
import React, { useEffect, useState } from 'react'
import {
  Row,
  Switch,
  Form,
  Checkbox,
  Button,
  Col,
  Input,
  Select,
  Typography,
  message,
  Modal,
  Popconfirm,
} from 'antd'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { loadStripe } from '@stripe/stripe-js'
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { AiFillDelete } from 'react-icons/ai'
import { awsconfig } from 'configs'
import { useAuthSelector } from 'store/authSlice/authReducer'
import {
  deleteUserCard,
  donateByStripe,
  getUserCards,
} from 'store/paymentSlice/paymentActions'
import { RiErrorWarningFill } from 'react-icons/ri'
import { formatAmount, REGEXS } from 'utils'
import * as Yup from 'yup'
import { TermAndConditionModal, AmountInput } from 'components/elements'

const { Title, Paragraph } = Typography

const amountValidation = REGEXS.amountRegex
const numberValidation = REGEXS.numberRegex

const schema = Yup.object().shape({
  askedAmount: Yup.string()
    .required('Required')
    .matches(amountValidation, 'Invalid amount'),
  includeEventCosts: Yup.string().required('Required'),
  card: Yup.string().required('Required'),
  agreeToTerms: Yup.string().required('Required'),
  splitDonation: Yup.string().required('Required'),
  splitnoOfMonths: Yup.string()
    .typeError('Required')
    .required('Required')
    .matches(numberValidation, 'Invalid amount'),
  repeatMonths: Yup.string().required('Required'),
  repeatnoOfMonths: Yup.string()
    .typeError('Required')
    .required('Required')
    .matches(numberValidation, 'Invalid amount'),
})

const creditCardValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  card: 'no-card-selected',
  askedAmount: null,
  includeEventCosts: true,
  agreeToTerms: false,
  splitDonation: false,
  splitnoOfMonths: null,
  repeatMonths: false,
  repeatnoOfMonths: null,
}

const CreditCardForm = ({ refreshEventData }) => {
  const [loading, setLoading] = useState(false)
  const [cards, setCards] = useState([])
  const [manageCardModalVisible, setManageCardModalVisible] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const { event, subscriberPaymentInfo, joinedEventDetails } =
    useEventSelector()
  const { user, location, geolocation } = useAuthSelector()
  const [form] = Form.useForm()

  Form.useWatch('card', form)
  Form.useWatch('askedAmount', form)
  Form.useWatch('includeEventCosts', form)
  Form.useWatch('repeatMonths', form)
  Form.useWatch('splitDonation', form)
  Form.useWatch('splitnoOfMonths', form)
  Form.useWatch('repeatnoOfMonths', form)
  Form.useWatch('agreeToTerms', form)

  const repeatMonths = form.getFieldValue('repeatnoOfMonths')
  const splitMonths = form.getFieldValue('splitnoOfMonths')

  const isSplit = form.getFieldValue('splitDonation')
  const isRepeat = form.getFieldValue('repeatMonths')

  const getCustomerCards = async () => {
    setLoading(true)
    try {
      const userCards = await getUserCards(user)
      setCards(userCards)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    getCustomerCards()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    form.setFieldsValue({
      askedAmount: event?.CurrentAsk || '0',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event?.CurrentAsk])

  /* eslint-disable */
  const onFinish = async (values) => {
    const askedAmountValue = values?.askedAmount
    if (askedAmountValue == '0') {
      message.error("Amount can't be zero")
      return false
    }

    const includeEventCostValue = values.includeEventCosts
    const twoPercentage = (2 / 100) * Number(askedAmountValue)
    const totalDonation = includeEventCostValue
      ? Number(askedAmountValue) + twoPercentage
      : askedAmountValue

    let repeatPayment = {
      split: {
        splitDonation: Boolean(values.splitDonation),
        months: values.splitDonation ? values.splitnoOfMonths || null : null,
      },
      repeat: {
        repeatMonths: Boolean(values.repeatMonths),
        months: values.repeatMonths ? values.repeatnoOfMonths || null : null,
      },
    }

    try {
      if (!values.agreeToTerms) {
        return message.error('You must agree to the terms and conditions')
      }

      // checking the allowed split months
      if (repeatPayment.split.splitDonation) {
        if (
          Number(repeatPayment.split.months) > Number(event?.splitPaymentMonths)
        ) {
          return message.error(
            `You can't split the donation for more than ${event?.splitPaymentMonths} months`,
          )
        }
      }

      // checking the allowed repeat months
      if (repeatPayment.repeat.repeatMonths) {
        if (
          Number(repeatPayment.repeat.months) >
          Number(event?.monthlyPaymentMonths)
        ) {
          return message.error(
            `You can't repeat the donation for more than ${event?.monthlyPaymentMonths} months`,
          )
        }
      }

      if (repeatPayment.repeat.repeatMonths) {
        repeatPayment.repeat.months = Number(repeatPayment.repeat.months) + 1
      }

      setLoading(true)
      // if new card is added
      if (!values.card || values.card === 'no-card-selected') {
        // elements are not loaded yet
        if (elements === null) {
          return
        }
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
        })
        // if there is an error
        if (error) {
          message.error(error?.message || 'Invalid Card Information')
          setLoading(false)
        } else {
          const data = {
            amount: totalDonation,
            cardToken: paymentMethod.id,
            stripeAccount: subscriberPaymentInfo?.stripe?.stripeId,
            card: null,
            user,
            event,
            eventCost: twoPercentage,
            joinedEventDetails,
            location,
            includeEventCost: includeEventCostValue,
            commission: subscriberPaymentInfo.markup,
            isBankAccount: false,
            repeatPayment,
            geolocation,
            // start - event cost values
            eventCostAmount: includeEventCostValue ? twoPercentage : 0,
            totalDonation: askedAmountValue,
            // end - event cost values
          }
          await donateByStripe(data)
          refreshEventData()
          message.success('Your donation is successfully submitted')
          form.resetFields()
          elements.getElement(CardElement).clear()
          getCustomerCards()
          setLoading(false)
        }
      } else {
        const data = {
          amount: totalDonation,
          card: values.card,
          cardToken: null,
          stripeAccount: subscriberPaymentInfo?.stripe?.stripeId,
          user,
          event,
          joinedEventDetails,
          location,
          eventCost: twoPercentage,
          includeEventCost: includeEventCostValue,
          commission: subscriberPaymentInfo.markup,
          isBankAccount: false,
          repeatPayment,
          geolocation,
          // start - event cost values
          eventCostAmount: includeEventCostValue ? twoPercentage : 0,
          totalDonation: askedAmountValue,
          // end - event cost values
        }
        await donateByStripe(data)
        refreshEventData()
        message.success('Your donation is successfully submitted')
        form.resetFields()
        setLoading(false)
      }
    } catch (error) {
      console.log('error', error)
      getCustomerCards()
      message.error(error?.response?.data?.message || 'Something Went Wrong')
      setLoading(false)
    }
  }

  const isNoCard = form.getFieldValue('card') === 'no-card-selected'

  const askedAmountValue = form.getFieldValue('askedAmount')
  const includeEventCostValue = form.getFieldValue('includeEventCosts')
  const isAgreed = !form.getFieldValue('agreeToTerms')

  const twoPercentage = (2 / 100) * Number(askedAmountValue)
  const totalDonation = includeEventCostValue
    ? Number(askedAmountValue) + twoPercentage
    : askedAmountValue

  const onDeleteCard = async (cardId) => {
    try {
      setLoading(true)
      await deleteUserCard(user, cardId)
      message.success('Card Deleted Successfully')
      getCustomerCards()
      setLoading(false)
    } catch (error) {
      message.error(
        error.resopnse.data.message || 'Error occured while deleting the card',
      )
      setLoading(false)
    }
  }

  const getDonateText = () => {
    if (isRepeat) {
      const totalRepeatDonationAmount = (repeatMonths || 0) * totalDonation
      const totalNumberofRepeatMonths = repeatMonths || 0
      const oneMonthAmount =
        totalRepeatDonationAmount / totalNumberofRepeatMonths
      return `You are planning donation that will repeat ${formatAmount(
        totalRepeatDonationAmount,
      )} in next ${totalNumberofRepeatMonths} months with a monthly payment of ${formatAmount(
        oneMonthAmount,
      )}. For keeping you posted your first donation will be made now of ${formatAmount(
        oneMonthAmount,
      )} and rest ${formatAmount(
        totalRepeatDonationAmount,
      )} in coming ${totalNumberofRepeatMonths} months. Please confirm by clicking below.`
    }
    if (isSplit) {
      const totalSplitDonationAmount = totalDonation
      const totalNumberofRepeatMonths = splitMonths || 0
      const oneMonthAmount = totalDonation / totalNumberofRepeatMonths
      return `You have planning donation that will split ${formatAmount(
        totalSplitDonationAmount,
      )} in next ${totalNumberofRepeatMonths} months with a monthly payment of ${formatAmount(
        oneMonthAmount,
      )}. Please confirm by clicking below.`
    }
    return `You are donating ${formatAmount(
      totalDonation,
    )}. Please confirm by clicking below.`
  }

  return (
    <div>
      {subscriberPaymentInfo?.stripe?.isConnected ? (
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="event"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row className="t-p-1">
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="askedAmount"
                label="Asked Amount"
                colon={false}
                rules={[creditCardValidation]}
              >
                <AmountInput
                  placeholder="Enter amount here"
                  value={form.getFieldValue('askedAmount')}
                  onChange={(value) =>
                    form.setFieldsValue({ askedAmount: value })
                  }
                  disabled={!event?.EditAskedAmount}
                />
              </Form.Item>
            </Col>
            {cards.length > 0 && (
              <Col xl={24} md={24} xs={24} sm={24}>
                <Form.Item
                  name="card"
                  label="Select Card"
                  colon={false}
                  rules={[creditCardValidation]}
                >
                  <Select
                    placeholder="Select Card"
                    style={{ width: '100%' }}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  >
                    <Select.Option key={-1} value="no-card-selected">
                      No Card Selected
                    </Select.Option>
                    {cards.map((item, index) => (
                      <Select.Option
                        value={item.cardId}
                        key={index}
                        className="t-capitalize"
                      >
                        {`${item.brand} - **** **** **** ${item.last4}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {cards.length > 0 && (
              <Col span={24}>
                <Row className="t-flex t-justify-end ">
                  <h1
                    onClick={() => setManageCardModalVisible(true)}
                    className="t-text-secondary-100 t-underline t-cursor-pointer"
                  >
                    Manage Cards
                  </h1>
                </Row>
              </Col>
            )}
            {isNoCard && (
              <Col xl={24} md={24} xs={24} sm={24} className={`t-mb-6`}>
                <h1 className="t-text-sm t-my-2 t-font-semibold">
                  Enter New Card Info
                </h1>
                <div
                  className={`t-p-2 ${
                    isNoCard && 't-rounded-md t-border t-border-grey-900'
                  }`}
                >
                  <CardElement
                    id="creditCardNumber"
                    options={{
                      style: {
                        base: {
                          fontSize: '15px',
                          fontSmoothing: 'antialiased',
                        },
                      },
                    }}
                  />
                </div>
              </Col>
            )}

            {event?.splitPaymentAllowed && (
              <>
                <Col
                  xl={isSplit ? 12 : 24}
                  md={isSplit ? 12 : 24}
                  xs={24}
                  sm={24}
                >
                  <Form.Item
                    name="splitDonation"
                    label="Split Payment"
                    colon={false}
                    rules={[creditCardValidation]}
                  >
                    <Switch disabled={isRepeat} />
                  </Form.Item>
                </Col>
                {isSplit && (
                  <>
                    <Col
                      xl={isSplit ? 12 : 24}
                      md={isSplit ? 12 : 24}
                      xs={24}
                      sm={24}
                    >
                      <Form.Item
                        name="splitnoOfMonths"
                        label="Number of Months"
                        colon={false}
                        rules={[creditCardValidation]}
                      >
                        <Input
                          placeholder="Number of Months"
                          disabled={isRepeat}
                          inputMode="numeric"
                          onChange={(e) =>
                            form.setFieldsValue({
                              splitnoOfMonths: e.target.value.replace(
                                /\D/g,
                                '',
                              ),
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Paragraph className="t-font-bold t-text-xs t-text-yellow-600 t-text-right">
                        Event creator allowed only {event?.splitPaymentMonths}{' '}
                        months
                      </Paragraph>
                    </Col>
                  </>
                )}
              </>
            )}
            {event?.monthlyPaymentAllowed && (
              <>
                <Col
                  xl={isRepeat ? 12 : 24}
                  md={isRepeat ? 12 : 24}
                  xs={24}
                  sm={24}
                >
                  <Form.Item
                    name="repeatMonths"
                    label="Months To Repeat"
                    colon={false}
                    rules={[creditCardValidation]}
                  >
                    <Switch disabled={isSplit} />
                  </Form.Item>
                </Col>
                {isRepeat && (
                  <>
                    <Col
                      xl={isRepeat ? 12 : 24}
                      md={isRepeat ? 12 : 24}
                      xs={24}
                      sm={24}
                    >
                      <Form.Item
                        name="repeatnoOfMonths"
                        label="Number of Months"
                        colon={false}
                        rules={[creditCardValidation]}
                      >
                        <Input
                          placeholder="Number of Months"
                          disabled={isSplit}
                          inputMode="numeric"
                          onChange={(e) =>
                            form.setFieldsValue({
                              repeatnoOfMonths: e.target.value.replace(
                                /\D/g,
                                '',
                              ),
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Paragraph className="t-font-bold t-text-xs t-text-yellow-600 t-text-right">
                        Event creator allowed only {event?.monthlyPaymentMonths}{' '}
                        months
                      </Paragraph>
                    </Col>
                  </>
                )}
              </>
            )}
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="includeEventCosts"
                label={
                  event.EventType === 'Fundraiser'
                    ? 'Include Event Fees'
                    : 'Include Campaign Fees'
                }
                colon={false}
                rules={[creditCardValidation]}
              >
                <Switch defaultChecked={true} />
              </Form.Item>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Paragraph className="t-text-justify">
                {getDonateText()}
              </Paragraph>
            </Col>
            {/* <Col xl={24} md={24} xs={24} sm={24}>
              <Row justify="end">
                <Title level={3}>
                  {`Total Donation = ${formatAmount(totalDonation)}`}
                </Title>
              </Row>
            </Col> */}
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="agreeToTerms"
                colon={false}
                rules={[creditCardValidation]}
              >
                <Checkbox
                  onChange={(e) =>
                    form.setFieldsValue({
                      agreeToTerms: e.target.checked,
                    })
                  }
                >
                  <TermAndConditionModal />
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24} className="text-right">
              <Button
                type="primary"
                className="t-w-full md:t-w-auto"
                htmlType="submit"
                loading={loading}
                disabled={!stripe || !elements || isAgreed}
              >
                {!isNoCard ? 'Donate' : 'Save card and donate'}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <div className="t-h-72 t-flex t-items-center t-justify-center t-flex-col">
          <RiErrorWarningFill
            fontSize="2.5rem"
            className="t-text-secondary-100"
          />
          <Title level={5}>
            Credit card payments are not configured for this event
          </Title>
          <Paragraph>You can use other payment methods</Paragraph>
        </div>
      )}
      <Modal
        centered
        title="Manage Cards"
        open={manageCardModalVisible}
        onCancel={() => setManageCardModalVisible(false)}
        footer={null}
      >
        <Col span={24} className="t-p-4">
          <div className="t-flex t-justify-between">
            <h1>Following are the cards attached to your account</h1>
            <h1>Total Cards : {cards.length}</h1>
          </div>
          <div>
            {cards.map((item, index) => (
              <div
                key={index}
                className="t-flex t-justify-between t-capitalize t-border-2 t-rounded-2xl t-shadow-lg t-p-4 t-my-4"
              >
                <h1>{`${item.brand} - **** **** **** ${item.last4}`}</h1>
                <Popconfirm
                  title="Are you sure you want to delete this card？"
                  placement="top"
                  loading={loading}
                  onConfirm={() => onDeleteCard(item.cardId)}
                  okText="Delete"
                  cancelText="Cancel"
                >
                  <AiFillDelete
                    fontSize={'1.2rem'}
                    className="t-text-red-500 t-cursor-pointer"
                  />
                </Popconfirm>
              </div>
            ))}
          </div>
        </Col>
      </Modal>
    </div>
  )
}

const stripePromise = loadStripe(awsconfig.stripe_publish_key)

function StripeProvider({ refreshEventData }) {
  return (
    <Elements stripe={stripePromise}>
      <CreditCardForm refreshEventData={refreshEventData} />
    </Elements>
  )
}

const CreditCard = StripeProvider

export { CreditCard }
