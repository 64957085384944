// We are not using this now will be deletd in future
import React, { useEffect, useState } from 'react'
import {
  Row,
  Switch,
  Form,
  Checkbox,
  Button,
  Col,
  message,
  Typography,
} from 'antd'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { addCashDonation } from 'store/paymentSlice/paymentActions'
import { formatAmount, REGEXS } from 'utils'
import * as Yup from 'yup'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { TermAndConditionModal, AmountInput } from 'components/elements'

const { Title } = Typography

const amountValidation = REGEXS.amountRegex

const schema = Yup.object().shape({
  askedAmount: Yup.string()
    .required('Required')
    .matches(amountValidation, 'Invalid amount'),
  includeEventCosts: Yup.string().required('Required'),
  agreeToTerms: Yup.string().required('Required'),
  collected: Yup.string().required('Required'),
})

const cashValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  askedAmount: null,
  includeEventCosts: true,
  agreeToTerms: false,
  collected: false,
}

const Cash = ({ refreshEventData }) => {
  const [loading, setLoading] = useState(false)
  const { event, joinedEventDetails } = useEventSelector()
  const { geolocation, user, location, organization } = useAuthSelector()
  const [form] = Form.useForm()

  Form.useWatch('agreeToTerms', form)
  Form.useWatch('askedAmount', form)
  Form.useWatch('includeEventCosts', form)
  Form.useWatch('collected', form)

  const isPrivateCampaign =
    event?.EventType === 'Campaign' &&
    event?.IsPrivate &&
    event?.OrganizationId === organization?.id

  useEffect(() => {
    const isPrivate =
      event?.EventType === 'Campaign' &&
      event?.IsPrivate &&
      event?.OrganizationId === organization?.id
    if (isPrivate) {
      form.setFieldsValue({ collected: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, event])

  useEffect(() => {
    form.setFieldsValue({
      askedAmount: event?.CurrentAsk || '0',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event?.CurrentAsk])

  /* eslint-disable */
  const onFinish = async (values) => {
    const askedAmountValue = values?.askedAmount
    const includeEventCostValue = values.includeEventCosts
    const twoPercentage = (2 / 100) * Number(askedAmountValue)
    const totalDonation = includeEventCostValue
      ? Number(askedAmountValue) + twoPercentage
      : askedAmountValue

    if (!values.agreeToTerms) {
      return message.error('You must agree to the terms and conditions')
    }
    try {
      setLoading(true)
      const data = {}

      data.eventId = event.Id
      data.eventName = event.Title

      if (values.collected) {
        // use the dummy name and email and phone number
        data.fullName = `Manual Entry (${organization?.Name})`
        data.email = `dummy_${organization?.Email}`
        data.organization = organization?.Name
        data.isManualEntry = true
        data.phoneNumber = user?.phone

        data.isAttendingOnline = false
        data.isDonatingAnonymously = false
        data.table = null

        data.status = 'Manual'
      } else {
        data.fullName = `${user?.firstname} ${user?.lastname}`
        data.email = user?.email
        data.phoneNumber = user?.phone

        data.isAttendingOnline = !joinedEventDetails?.isRemote
        data.isDonatingAnonymously = joinedEventDetails?.donateAnonymously
        data.table = joinedEventDetails?.tableName || null

        data.status = 'Pending'
      }
      data.zipCode = location?.zipcode
      data.payType = 'Cash'
      data.transactionType =
        event.EventType === 'Fundraiser' ? 'event' : 'campaign'

      data.amount = totalDonation
      data.contributeToEventCost = values?.includeEventCosts
      data.geolocation = geolocation

      await addCashDonation(data)
      refreshEventData()
      if (values.collected) {
        message.success('New payment entry is added into the donation')
      } else {
        message.success('A volunteer will contact you shortly')
      }
      form.resetFields()
      setLoading(false)
    } catch (error) {
      console.log('error', error)
      message.error('Something went wrong')
      setLoading(false)
    }
  }

  const askedAmountValue = form.getFieldValue('askedAmount')

  const includeEventCostValue = form.getFieldValue('includeEventCosts')
  // const isCashOrCheckCollected = form.getFieldValue('collected')

  const twoPercentage = (2 / 100) * Number(askedAmountValue)
  const totalDonation = includeEventCostValue
    ? Number(askedAmountValue) + twoPercentage
    : askedAmountValue

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        key={0}
        onFinish={onFinish}
        name="event"
        initialValues={initialValues}
        scrollToFirstError
      >
        <Row className="t-p-3">
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="askedAmount"
              label="Asked Amount"
              colon={false}
              rules={[cashValidation]}
            >
              <AmountInput
                placeholder="Enter amount here"
                value={form.getFieldValue('askedAmount')}
                onChange={(value) =>
                  form.setFieldsValue({ askedAmount: value })
                }
                disabled={!event?.EditAskedAmount}
              />
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="includeEventCosts"
              label={
                event.EventType === 'Fundraiser'
                  ? 'Include Event Fees'
                  : 'Include Campaign Fees'
              }
              colon={false}
              rules={[cashValidation]}
            >
              <Switch defaultChecked={true} />
            </Form.Item>
          </Col>
          {isPrivateCampaign && (
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="collected"
                label="Cash/Checks Collected?"
                colon={false}
                rules={[cashValidation]}
              >
                <Switch defaultChecked={true} />
              </Form.Item>
            </Col>
          )}
          <Col xl={24} md={24} xs={24} sm={24}>
            <Row justify="end">
              <Title level={3}>
                {`Total Donation = ${formatAmount(totalDonation)}`}
              </Title>
            </Row>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="agreeToTerms"
              colon={false}
              rules={[cashValidation]}
            >
              <Checkbox
                onChange={(e) =>
                  form.setFieldsValue({
                    agreeToTerms: e.target.checked,
                  })
                }
              >
                <TermAndConditionModal />
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={24} className="text-right">
            <Button
              loading={loading}
              type="primary"
              className="t-w-full md:t-w-auto"
              htmlType="submit"
            >
              Donate via cash
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export { Cash }
