import React, { useEffect, useState } from 'react'
import {
  message,
  Card,
  Collapse,
  Table,
  Button,
  Select,
  Space,
  Modal,
  List,
  Tooltip,
  Typography,
  Spin,
} from 'antd'

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { MdOutlineSimCardDownload } from 'react-icons/md'

import moment from 'moment'
import Api from 'api'
import { useAuthSelector } from 'store/authSlice/authReducer'
import RevenueForcastChart from '../../../../../../../components/common/charts/revenueForcastChart'
import ChartSkeleton from '../../../../../../../components/common/skeleton/chartSkeleton'
import TableSkeleton from '../../../../../../../components/common/skeleton/tableSkeleton'

const {
  generateMonths,
  generateMonthsByDate,
  formatDisplayName,
  downloadCSV,
  formatDate,
  formatAmount,
  formateDateTimeToLocal,
} = require('../../../../../../../utils')

const { Panel } = Collapse
const { Option } = Select
const { Title, Text } = Typography

const MonthlyRevenueForcast = () => {
  const history = useHistory()
  const { organization } = useAuthSelector()
  const [loading, setLoading] = useState(true)

  const [selectedMonth, setSelectedMonth] = useState(moment().format('YYYY-MM'))
  const [donationType, setDonationType] = useState('orgPledges')
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [apiDataTable, setApiDataTable] = useState({})
  const [filteredData, setFilteredData] = useState([])
  const [selectedRecord, setSelectedRecord] = useState(null)
  // const [showCheckbox, setShowCheckbox] = useState(false)
  // const [includePledges, setIncludePledges] = useState(false)
  const [grandTotal, setGrandTotal] = useState(0)
  const [showEventTitle, setShowEventTitle] = useState(false)

  const detailFields =
    donationType === 'programSplitPayment'
      ? [
          'UserName',
          'UserEmail',
          'UserPhoneNumber',
          'remainingPayments',
          'NumberOfSplit',
          'Frequency',
          'Amount',
          'NumberOfPayments',
        ]
      : [
          'DonorName',
          'DonorEmail',
          'donatedAmount',
          'DonorPhone',
          // 'ScheduleDate',
        ]

  const csvColumns =
    donationType === 'orgPledges'
      ? [
          'DonorName',
          'DonorEmail',
          'donatedAmount',
          'DonorPhone',
          'ScheduleDate',
          'PaymentStatus',
        ]
      : donationType === 'programSplitPayment'
      ? [
          'UserName',
          'UserEmail',
          'UserPhoneNumber',
          'remainingPayments',
          'NumberOfSplit',
          'Frequency',
          'Amount',
          'NumberOfPayments',
          'NextFormatedSpitPaymentDate',
          'NextFormatedSpitPaymentTime',
        ]
      : [
          'EventTitle',
          'DonorName',
          'DonorEmail',
          'donatedAmount',
          'DonorPhone',
          'ScheduleDate',
          'PaymentStatus',
        ]
  const csvHeaderMapping =
    donationType === 'orgPledges'
      ? {
          DonorName: 'Donor Name',
          DonorEmail: 'Donor Email',
          donatedAmount: 'Pledge Amount',
          DonorPhone: 'Donor Phone',
          ScheduleDate: 'Schedule Date',
          PaymentStatus: 'Payment Status',
        }
      : donationType === 'programSplitPayment'
      ? {
          UserName: 'Donor Name',
          UserEmail: 'Donor Emai',
          UserPhoneNumber: 'Donor Phone Number',
          remainingPayments: 'Remaining Payments',
          NumberOfSplit: 'Number of Split Payment',
          Frequency: 'Frequency',
          Amount: 'Amount',
          NumberOfPayments: 'Number of Payment',
          NextFormatedSpitPaymentDate: 'Next Payment Date',
          NextFormatedSpitPaymentTime: 'Next Payment Time',
        }
      : {
          EventTitle: 'Event Title',
          DonorName: 'Donor Name',
          DonorEmail: 'Donor Email',
          donatedAmount: 'Pledge Amount',
          DonorPhone: 'Donor Phone',
          ScheduleDate: 'Schedule Date',
          PaymentStatus: 'Payment Status',
        }

  const formatField = (field, value) => {
    const fieldsWithDollar = [
      'donatedAmount',
      'costAmountPerMonth',
      'TotalAmount',
      'amount',
      'Amount',
    ]
    if (fieldsWithDollar.includes(field)) {
      const formattedAmount = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value)
      return formattedAmount
    }
    if (field === 'ScheduleDate') {
      return formatDate(value)
    }
    return value
  }

  const [chartData, setChartData] = useState({
    options: {
      chart: { id: 'basic-bar' },
      xaxis: {
        categories: generateMonths().slice(0, 6),
      },
    },
    series: [
      {
        name: 'Org Donations',
        data: [],
      },
      {
        name: 'Migrated Donations',
        data: [],
      },
      {
        name: 'Events/Campaigns Donations',
        data: [],
      },
    ],
  })

  const [chartDataAmount, setChartDataAmount] = useState({
    options: {
      chart: { id: 'basic-bar' },
      xaxis: {
        categories: generateMonths().slice(0, 6),
      },
    },
    series: [
      {
        name: 'Org Donations',
        data: [],
      },
      {
        name: 'Migrated Donations',
        data: [],
      },
      {
        name: 'Events/Campaigns Donations',
        data: [],
      },
    ],
  })

  const transformApiDataToChartData = (apiData) => {
    const months = generateMonthsByDate()
    const transformedSeries = Object.keys(apiData).map((donationCategory) => {
      const monthlyCounts = months.map(
        (month) => Math.round(apiData[donationCategory][month]?.count) || 0,
      )
      return { name: donationCategory, data: monthlyCounts }
    })

    return {
      ...chartData,
      series: transformedSeries,
    }
  }

  const transformApiDataToChartDataAmount = (apiData) => {
    const months = generateMonthsByDate()
    const transformedSeriesTotalAmount = Object.keys(apiData).map(
      (donationCategory) => {
        const monthlyCounts = months.map(
          (month) => apiData[donationCategory][month]?.totalDonatedAmount || 0,
        )
        return { name: donationCategory, data: monthlyCounts }
      },
    )
    return {
      ...chartDataAmount,
      series: transformedSeriesTotalAmount,
    }
  }

  const handleDonationTypeChange = (value) => {
    setDonationType(value)
    // setShowCheckbox(value === 'Events/Campaigns Donations')
    setShowEventTitle(!showEventTitle)
  }

  const getData = () => {
    setGrandTotal(0)
    setLoading(true) // Set loading to true when the data fetching starts
    const chartOptions = {
      ...chartDataAmount.options, // Your existing options
      yaxis: {
        labels: {
          formatter(value) {
            const formattedAmount = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(value)
            return formattedAmount
            // Adds a dollar sign to the y-axis labels
          },
        },
      },
      tooltip: {
        y: {
          formatter(value) {
            const formattedAmount = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(value)
            return formattedAmount
            // Adds a dollar sign to the y-axis labels
          },
        },
      },
    }

    const chartOptionsCount = {
      ...chartDataAmount.options, // Your existing options
      yaxis: {
        labels: {
          formatter(val) {
            return val.toFixed(0) // No decimals, round to nearest whole number
          },
        },
      },
      tooltip: {
        y: {
          formatter(val) {
            return val.toFixed(0) // No decimals, round to nearest whole number
          },
        },
      },
    }

    Api.get(`reports/monthly-revenue-forcast/${organization.id}`)
      .then((result) => {
        const newChartData = transformApiDataToChartData(result.response)
        const newChartDataTotalAmount = transformApiDataToChartDataAmount(
          result.response,
        )
        setChartData({ ...newChartData, options: chartOptionsCount })
        setChartDataAmount({
          ...newChartDataTotalAmount,
          options: chartOptions,
        })
        setApiDataTable(result.response)

        setLoading(false) // Set loading to false when the data fetching completes
      })
      .catch((error) => {
        console.log('error', error)
        message.error(
          error?.exceptionMessage || 'An error occured in fetching report',
        )
      })
  }
  const formatDays = (day) => {
    if (day > 1) {
      return `${Math.abs(day)} days`
    }
    return `Overdue`
  }
  const columns =
    donationType === 'orgPledges'
      ? [
          { title: 'Donor Name', dataIndex: 'DonorName', key: 'DonorName' },
          { title: 'Donor Email', dataIndex: 'DonorEmail', key: 'DonorEmail' },
          // { title: 'Event Title', dataIndex: 'EventTitle', key: 'EventTitle' },
          {
            title: 'Due In',
            dataIndex: 'dueDays',
            key: 'dueDays',
            render: (dueDays) => formatDays(dueDays),
          },
          { title: 'Donor Phone', dataIndex: 'DonorPhone', key: 'DonorPhone' },
          {
            title: 'Pledge Amount',
            dataIndex: 'donatedAmount',
            key: 'donatedAmount',
            className: 'align-right',
            align: 'center',
            render: (text) => {
              const formattedAmount = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(text)
              return formattedAmount
            },
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <Button onClick={() => showDetails(record)}>Details</Button>
            ),
          },
        ]
      : donationType === 'programSplitPayment'
      ? [
          { title: 'Donor Name', dataIndex: 'UserName', key: 'UserName' },
          { title: 'Donor Email', dataIndex: 'UserEmail', key: 'UserEmail' },
          // { title: 'Event Title', dataIndex: 'EventTitle', key: 'EventTitle' },

          {
            title: 'Donor Phone',
            dataIndex: 'UserPhoneNumber',
            key: 'UserPhoneNumber',
          },
          {
            title: 'Amount',
            dataIndex: 'Amount',
            key: 'Amount',
            className: 'align-right',
            align: 'center',
            render: (text) => {
              const formattedAmount = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(text)
              return formattedAmount
            },
          },
          {
            title: 'Remaining Payments',
            dataIndex: 'remainingPayments',
            key: 'remainingPayments',
          },
          {
            title: 'No of Split Payment',
            dataIndex: 'NumberOfSplit',
            key: 'NumberOfSplit',
          },
          {
            title: 'Next Payment Date',
            dataIndex: 'NextPaymentDate',
            key: 'NextPaymentDate',
            render: (time) => formateDateTimeToLocal(time, 'datetime'),
            sorter: (a, b) => {
              const dateA = new Date(a.NextPaymentDate)
              const dateB = new Date(b.NextPaymentDate)
              return dateB - dateA
            },
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <Button onClick={() => showDetails(record)}>Details</Button>
            ),
          },
        ]
      : [
          { title: 'Donor Name', dataIndex: 'DonorName', key: 'DonorName' },
          { title: 'Donor Email', dataIndex: 'DonorEmail', key: 'DonorEmail' },
          { title: 'Event Title', dataIndex: 'EventTitle', key: 'EventTitle' },
          {
            title: 'Due In',
            dataIndex: 'dueDays',
            key: 'dueDays',
            render: (dueDays) => formatDays(dueDays),
          },
          { title: 'Donor Phone', dataIndex: 'DonorPhone', key: 'DonorPhone' },
          {
            title: 'Pledge Amount',
            dataIndex: 'donatedAmount',
            key: 'donatedAmount',
            className: 'align-right',
            align: 'center',
            render: (text) => {
              const formattedAmount = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(text)
              return formattedAmount
            },
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <Button onClick={() => showDetails(record)}>Details</Button>
            ),
          },
        ]

  // Handle dropdown changes
  useEffect(() => {
    setGrandTotal(0)
    setLoading(true)
    let orgPledgesData =
      apiDataTable['Org Pledges']?.[selectedMonth]?.data || []
    orgPledgesData = orgPledgesData.filter(
      (item) => item.PaymentStatus === 'Pending',
    )
    const programSplitPaymentData =
      apiDataTable['Program Split Patyment']?.[selectedMonth]?.data || []

    let newData =
      apiDataTable['Events/Campaigns Pledges']?.[selectedMonth]?.data || []

    newData = newData.filter((item) => item.PaymentStatus === 'Pending')
    // Filter data based on the selected donation type
    if (donationType === 'eventPledges') {
      newData = newData.filter((item) => item.TransactionType === 'event')
    } else if (donationType === 'campaignPledges') {
      newData = newData.filter((item) => item.TransactionType === 'campaign')
    } else if (donationType === 'fundraiserPledges') {
      newData = newData.filter((item) => item.TransactionType === 'fundraiser')
    } else if (donationType === 'orgPledges') {
      newData = orgPledgesData
    } else if (donationType === 'programSplitPayment') {
      newData = programSplitPaymentData.map((element) => ({
        ...element,
        NextFormatedSpitPaymentDate: formateDateTimeToLocal(
          element.NextPaymentDate,
          'date',
        ),
        NextFormatedSpitPaymentTime: formateDateTimeToLocal(
          element.NextPaymentDate,
          'time',
        ),
        NextFormatedSpitPaymentDateTime: formateDateTimeToLocal(
          element.NextPaymentDate,
          'datetime',
        ),
      }))
    }

    // Add dueDays property to each item
    newData = newData.map((item) => {
      const dueDays = item.ScheduleDate
        ? moment(item.ScheduleDate).diff(moment(), 'days')
        : undefined
      return { ...item, dueDays }
    })

    // Calculate grand total
    const total = newData.reduce((acc, item) => {
      const amount = parseFloat(item.donatedAmount || item.amount || 0)
      return acc + amount
    }, 0)

    setGrandTotal(formatAmount(total))
    setFilteredData(newData)
    setLoading(false)
    // eslint-disable-next-line
  }, [apiDataTable, selectedMonth, donationType])

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  // Show modal with details
  const showDetails = (record) => {
    setSelectedRecord(record)
    setIsModalVisible(true)
  }

  return (
    <>
      <Card
        className="header-solid"
        bordered={false}
        title={
          <div className="t-flex t-flex-wrap t-justify-between">
            <div className="t-flex t-space-x-4">
              <IoMdArrowRoundBack
                fontSize="1.5rem"
                className="t-cursor-pointer t-min-w-[1.5rem]"
                id="back"
                onClick={() => history.push('/reports')}
              />
              <h5 className="font-semibold">Monthly Revenue Forecast</h5>
            </div>
          </div>
        }
      >
        <Collapse defaultActiveKey={['1']}>
          <Panel header="Collapse" key="1">
            <div className="t-flex t-justify-between t-items-start">
              {/* First Chart Container */}
              {loading ? (
                <ChartSkeleton />
              ) : (
                <RevenueForcastChart
                  chartData={chartData}
                  title="Donations Count"
                />
              )}

              {/* Second Chart Container */}
              {loading ? (
                <ChartSkeleton />
              ) : (
                <RevenueForcastChart
                  chartData={chartDataAmount}
                  title="Donations Amount"
                />
              )}
            </div>
          </Panel>
        </Collapse>
      </Card>

      {/* Donations Component */}
      <Card
        className="header-solid"
        bordered={false}
        style={{ marginTop: '20px' }}
        title={
          <div className="t-flex t-flex-wrap t-justify-between">
            <div className="t-flex t-space-x-4">
              <IoMdArrowRoundBack
                fontSize="1.5rem"
                className="t-cursor-pointer t-min-w-[1.5rem]"
                id="back"
                onClick={() => history.push('/reports')}
              />
              <h5 className="font-semibold">Monthly Revenue Forecast</h5>
            </div>
            <div className="t-flex t-justify-end t-items-center t-space-x-2 t-mt-4 sm:t-mt-0">
              <Tooltip title="Download CSV">
                <MdOutlineSimCardDownload
                  fontSize="1.7rem"
                  id="download"
                  className="t-text-secondary-100 t-cursor-pointer"
                  onClick={() =>
                    downloadCSV(
                      filteredData,
                      csvColumns,
                      csvHeaderMapping,
                      'Monthly Revenue Forecast Graph',
                    )
                  }
                />
              </Tooltip>
            </div>
          </div>
        }
      >
        <Collapse defaultActiveKey={['1']}>
          <Panel header="Collapse" key="1">
            <div>
              <Space direction="vertical">
                <Title level={4}>
                  Total Forecast:{' '}
                  <span style={{ marginLeft: 10 }}>
                    {' '}
                    {loading ? (
                      <Spin />
                    ) : (
                      <Text type="success">
                        {grandTotal.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Text>
                    )}
                  </span>
                </Title>
              </Space>
            </div>
            <div style={{ padding: 24 }}>
              <div style={{ marginBottom: 22 }}>
                <Select
                  defaultValue={selectedMonth}
                  onChange={setSelectedMonth}
                >
                  {Array.from({ length: 6 }, (_, i) =>
                    moment().add(i, 'months').format('YYYY-MM'),
                  ).map((month) => (
                    <Option key={month} value={month}>
                      {moment(month).format('MMMM YYYY')}
                    </Option>
                  ))}
                </Select>
                <Select
                  defaultValue={donationType}
                  onChange={handleDonationTypeChange}
                  style={{ marginLeft: 15, width: '18ll0px' }}
                >
                  <Option value="orgPledges">Org Pledges</Option>
                  <Option value="campaignPledges">Campaign Pledges</Option>
                  <Option value="eventPledges">Event Pledges</Option>
                  {/* <Option value="fundraiserPledges">Fundraiser Pledges</Option> */}
                  <Option value="programSplitPayment">
                    Programs Split Payment
                  </Option>
                </Select>
                {/* {showCheckbox && (
                  <Checkbox
                    onChange={handleIncludePledgesChange}
                    style={{ marginLeft: 15 }}
                  >
                    Include Pledges
                  </Checkbox>
                )} */}
              </div>
              <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
              >
                {loading ? (
                  <TableSkeleton />
                ) : (
                  <Table
                    tableLayout="auto"
                    dataSource={filteredData}
                    columns={columns}
                    rowKey="id"
                    bordered={false}
                    pagination={false}
                    className="td-right"
                    scroll={{
                      x: 'max-content',
                    }}
                  />
                )}
              </Space>
              <Modal
                title="Details"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
              >
                {selectedRecord && (
                  <List
                    size="small"
                    bordered
                    dataSource={detailFields.map((field) => ({
                      title: formatDisplayName(field),

                      value: formatField(field, selectedRecord[field]),
                    }))}
                    renderItem={(item) => (
                      <List.Item>
                        <strong>{item.title}:</strong> {item.value}
                      </List.Item>
                    )}
                  />
                )}
              </Modal>
            </div>
          </Panel>
        </Collapse>
      </Card>
    </>
  )
}

export { MonthlyRevenueForcast }
