import { Auth, Amplify } from 'aws-amplify'
import { awsconfig } from 'configs/aws-exports'
import { message, notification } from 'antd'
import { authActions } from 'store/authSlice/authReducer'
import { eventActions } from 'store/eventSlice/eventReducer'
import { favouriteActions } from 'store/favouriteSlice/favouriteReducer'
import { organizationActions } from 'store/organizationSlice/organizationReducer'
import { programActions } from 'store/programSlice/programReducer'
import { serviceActions } from 'store/serviceSlice/serviceReducer'
import { volunteerActions } from 'store/volunteerSlice/volunteerReducer'
import Api, { makeRequest } from 'api'
import { roomActions } from 'store/roomSlice/roomReducer'

Amplify.configure(awsconfig)

export const checkUserExist = (phone, goTo) => (dispatch) => {
  dispatch(authActions.setLoading(true))
  Api.get(`/users/check-phone-number?phone=${encodeURIComponent(phone)}`)
    .then(() => {
      dispatch(authActions.setUserPhone(phone))
      goTo('signup')
      dispatch(authActions.setLoading(false))
      return null
    })
    .catch((err) => {
      const data = err?.response?.data?.message
      if (data !== 'Suspended Account') {
        return dispatch(sendOtp(phone, goTo))
      }
      message.error('Account Suspended')
      dispatch(authActions.setLoading(false))
      return null
    })
}

export const signInAfterDonation = (user, code, action) => () => {
  Auth?.sendCustomChallengeAnswer(user, code)
    .then(() => {
      message.success('Your phone number is registered')
      if (action) {
        action()
      }
    })
    .catch(() => {
      message.error('Incorrect OTP code. Click RESEND to recieve a new code.')
    })
}

export const sendOtp = (phone, goTo) => (dispatch) => {
  Auth.signIn(phone)
    .then((res) => {
      dispatch(authActions.setTempUser(res))
      dispatch(authActions.setUserPhone(phone))
      if (goTo) {
        goTo('otp')
      }
    })
    .catch((error) => {
      console.log('error', error)
    })
    .finally(() => dispatch(authActions.setLoading(false)))
}
export const signIn = (user, code, navigate) => (dispatch) => {
  dispatch(authActions.setLoading(true))
  Auth?.sendCustomChallengeAnswer(user, code)
    .then(() => {
      Auth.currentSession({ bypassCache: true })
        .then((actualUser) => {
          message.success('Logged In Successfully')
          dispatch(authActions.setUserPhone(null))
          dispatch(authActions.setTempUser(null))
          dispatch(authActions.setToken(actualUser?.idToken?.jwtToken))
          const roles = actualUser?.accessToken?.payload['cognito:groups']
          if (
            actualUser &&
            actualUser.hasOwnProperty('accessToken') &&
            actualUser.accessToken.hasOwnProperty('payload')
          ) {
            dispatch(setUserDetail(roles, navigate))
          }
        })
        .catch((error) => console.log('error', error))
        .finally(() => dispatch(authActions.setLoading(false)))
    })
    .catch(() => {
      message.error('Incorrect OTP code. Click RESEND to recieve a new code.')
    })
    .finally(() => dispatch(authActions.setLoading(false)))
}

export const signUp = (phoneNumber, attributes, goTo) => async (dispatch) => {
  dispatch(authActions.setLoading(true))
  const data = {
    username: phoneNumber,
    password: 'AAAA1234$aaaaa',
    attributes,
  }
  Auth.signUp(data)
    .then(() => {
      sendEmail(
        attributes.email,
        `${attributes?.given_name} ${attributes?.family_name}`,
      )
      dispatch(sendOtp(phoneNumber, goTo))
    })
    .catch((error) => console.log('error', error))
    .finally(() => dispatch(authActions.setLoading(false)))
}

export const isEmailAlreadyExist = async (email) => {
  const response = await Api.get(`/users/is-email-exist?email=${email}`)
  return response
}

export const signUpWithoutOtp = async (phoneNumber, attributes) => {
  const data = {
    username: phoneNumber,
    password: 'AAAA1234$aaaaa',
    attributes,
  }
  const response = await Auth.signUp(data)
  return response
}

export const logout = (action) => async (dispatch) => {
  if (action) {
    action()
  }
  notification.destroy()
  localStorage.clear()
  dispatch(authActions.reset())
  dispatch(eventActions.reset())
  dispatch(favouriteActions.reset())
  dispatch(organizationActions.reset())
  dispatch(programActions.reset())
  dispatch(serviceActions.reset())
  dispatch(volunteerActions.reset())
  dispatch(roomActions.reset())
}

export const getUser = async () => {
  const response = await Auth.currentAuthenticatedUser({ bypassCache: true })
  return response
}

export const setUserDetail = (roles, navigate) => (dispatch) => {
  dispatch(authActions.setLoading(true))
  Auth.currentAuthenticatedUser({ bypassCache: true })
    .then((res) => {
      const userDetail = {
        id: res.attributes.sub,
        firstname: res.attributes.given_name,
        lastname: res.attributes.family_name,
        email: res.attributes.email,
        phone: res.attributes.phone_number,
        email_verified: res.attributes.email_verified,
        phone_number_verified: res.attributes.phone_number_verified,
        roles,
      }
      dispatch(authActions.setUser(userDetail))
      dispatch(getOrganization(res.attributes.email))
      if (navigate) {
        const urlSearchParams = new URLSearchParams(window.location.search)
        const params = Object.fromEntries(urlSearchParams.entries())
        if (params?.url) {
          navigate(params?.url)
        } else {
          navigate('/welcome')
        }
      }
    })
    .finally(() => dispatch(authActions.setLoading(false)))
}

export const getOrganization = (email) => (dispatch) => {
  Api.get('/org/all')
    .then((res) => {
      const orgs = res.results.filter(
        (x) => x.CreatedBy === email && !x.deleted,
      )
      const org = orgs[0]
      dispatch(authActions.setOrganization(org))
    })
    .catch((error) => console.log('error', error))
}

// export const getUserPromptedPledges = () => () => {
//   Api.get('/events/getUserPromptedPledges')
//     .then((res) => {
//       if (res && res.Reminder === true) {
//         notification.info({
//           message: 'Pledge Reminder',
//           description: (
//             <div>
//               Please consider fulfilling your pledge commitments here:{' '}
//               <a
//                 href={`${window.location.origin}/settings/account?key=2&ytdPledges=true`}
//                 className="t-font-bold t-text-secondary-100"
//                 rel="noreferrer"
//               >
//                 link
//               </a>{' '}
//             </div>
//           ),
//           duration: 0,
//         })
//       }
//     })
//     .catch((error) => console.log('error', error))
//     .finally(() => getUserRejectedEvent())
// }

// export const getUserRejectedEvent = () => {
//   Api.get('/events/getUserRejectedEvent')
//     .then((res) => {
//       if (res && res.Reminder === true) {
//         notification.warning({
//           message: 'Action Needed',
//           description: (
//             <div>
//               {res.Message}{' '}
//               <a
//                 href={`${window.location.origin}/settings/manage-organization/my-events?key=Rejected`}
//                 className="t-font-bold t-text-secondary-100"
//                 rel="noreferrer"
//               >
//                 Click here
//               </a>{' '}
//               to view
//             </div>
//           ),
//           duration: 0,
//         })
//       }
//     })
//     .catch((error) => console.log('error', error))
// }

export const uploadFile = async (file, fileName) => {
  const data = new FormData()
  data.append('photos', file)
  try {
    const response = await Api.put('files/upload', data)
    return response
  } catch (error) {
    message.error(`Unable to upload ${fileName || ''}`)
    const errorMessage = error?.message || 'Unable to upload file'
    throw errorMessage
  }
}

export const getLocation = (lat, lng) => async (dispatch) => {
  Api.put('donor/getZipCodeByPosition', { lat, lon: lng })
    .then((res) => {
      dispatch(
        authActions.setLoaction({
          data: {
            zipcode: res?.results?.zip,
            lat,
            lng,
            city: res?.results?.city,
            state: res?.results?.state,
            country: res?.results?.country,
          },
          customLocation: false,
        }),
      )
    })
    .catch((error) => console.log('error', error))
}

export const getAddressByZipcode = (zipCode) => async (dispatch) => {
  Api.put('donor/addressByZipCode', { zipCode })
    .then((res) => {
      dispatch(
        authActions.setLoaction({
          data: {
            zipcode: res?.results?.zip,
            lat: res?.results?.latitude,
            lng: res?.results?.longitude,
            city: res?.results?.city,
            state: res?.results?.state,
            country: res?.results?.country,
          },
          customLocation: true,
        }),
      )
      message.success('Success', 5)
    })
    .catch((error) => {
      console.log('error', error)
      message.error('Zipcode not found')
    })
}

export const getDefaultPaymentAccounts = async (organizationId) => {
  const response = await Api.get(
    `/event/account-info?organizationId=${organizationId}`,
  )
  return response
}

export const getGovDocs = () => (dispatch) => {
  const codeId = 100001
  dispatch(authActions.setLoading(true))
  Api.get(`/admin/codeList?id=${codeId}`)
    .then((res) => {
      dispatch(authActions.setGovDocs(res.results))
      dispatch(authActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(authActions.setLoading(false))
    })
}

export const addDoc = (data) => (dispatch) => {
  dispatch(authActions.setLoading(true))
  Api.put('admin/addCodes', data)
    .then(() => {
      dispatch(getGovDocs())
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(authActions.setLoading(false))
    })
}

export const updateDocStatus = (codeId, isActivate) => (dispatch) => {
  dispatch(authActions.setLoading(true))
  if (isActivate) {
    Api.get(`/admin/activeCode/${codeId}`)
      .then(() => {
        dispatch(getGovDocs())
      })
      .catch((error) => {
        console.log('error', error)
        dispatch(authActions.setLoading(false))
      })
  } else {
    Api.get(`/admin/disableCode/${codeId}`)
      .then(() => {
        dispatch(getGovDocs())
      })
      .catch((error) => {
        console.log('error', error)
        dispatch(authActions.setLoading(false))
      })
  }
}

export const getUserContributions = () => (dispatch) => {
  /* eslint-disable */

  dispatch(authActions.setContributionLoading(true))
  Api.get('/adminReports/getDonorContributionList')
    .then(async (res) => {
      dispatch(authActions.setContributions(res?.results))

      const orgYTDResponse = await Api.get(
        '/adminReports/getYTDDonationList?type=org',
      )
      const orgResponse = await Api.get(
        '/adminReports/getLastYearDonationList?type=org',
      )
      const indvResponse = await Api.get(
        `/adminReports/getLastYearDonationList?type=indv`,
      )
      const ytdPledges = await Api.get(`/adminReports/getYTDPledgeList`)

      const donations = orgYTDResponse?.results?.sort(function compare(a, b) {
        var dateA = new Date(a.PaymentDate)
        var dateB = new Date(b.PaymentDate)
        return dateB - dateA
      })
      dispatch(authActions.setYTDOrg(donations))
      dispatch(authActions.setLastYearOrg(orgResponse?.results))
      dispatch(authActions.setLastYearItemized(indvResponse?.results))

      const pledges = ytdPledges?.results?.sort(function compare(a, b) {
        var dateA = new Date(a.ScheduleDate)
        var dateB = new Date(b.ScheduleDate)
        return dateA - dateB
      })
      dispatch(authActions.setYTDPledges(pledges))
      dispatch(authActions.setContributionLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(authActions.setContributionLoading(false))
    })
}

export const generatePDFAndEmail = (payload) => (dispatch) => {
  dispatch(authActions.setLoading(true))
  Api.put('/adminReports/generatePdfandEmail', payload)
    .then(() => {
      dispatch(authActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(authActions.setLoading(false))
    })
}

export const sendEmail = async (email, username) => {
  const response = await Api.put(`/users/send-email`, {
    email,
    pointUrl: awsconfig.API.endpoints[0].api_url,
    name: username || '',
  })
  return response
}

export const publicDonation = async (payload) => {
  const isBankAccount = payload?.isBankAccount
  const isRecurringPayment = payload?.repeatPayment?.repeat?.repeatMonths
  let response
  if (isBankAccount) {
    if (isRecurringPayment) {
      response = await Api.post('donation/stripe/recurring-bank-debit', payload)
    } else {
      response = await Api.post('donation/stripe/bank-debit', payload)
    }
  } else {
    if (isRecurringPayment) {
      response = await Api.post('donation/stripe/recurring', payload)
    } else {
      response = await Api.post('donation/stripe/credit-card', payload)
    }
  }
  return response
}

export const publicPaypalDonation = async (payload) => {
  const response = await Api.post('donation/paypal/credit-card', payload)
  return response
}

export const publicSeedDonation = async (payload, organizationId) => {
  const isBankAccount = payload?.isBankAccount
  let response
  if (isBankAccount) {
    response = await Api.post(
      'donation/stripe/seed-bank-debit-donation',
      payload,
    )
  } else {
    response = await makeRequest(
      'post',
      'donation/stripe/seed-donation',
      {
        organization: organizationId,
      },
      payload,
    )
    // response = await Api.post('donation/stripe/seed-donation', payload)
  }
  return response
}

export const publicPaypalSeedDonation = async (payload) => {
  const response = await Api.post('donation/paypal/seed-donation', payload)
  return response
}

export const addPledge = async (data) => {
  const { pledgeType, amount, repeatPayment, organization, event, user } = data

  const isEventPledge = pledgeType === 'event'
  const isMonthly = repeatPayment?.repeat?.repeatMonths ?? false

  // Extracting organizationId
  const organizationId = isEventPledge
    ? event?.organization?.id
    : organization?.id

  // Extracting transactionType
  const transactionType = isEventPledge
    ? event?.EventType === 'Fundraiser'
      ? 'event'
      : 'campaign'
    : 'organization'

  // Extracting Donor Detials
  const donorId = user?.id
  const donorName = `${user?.firstname} ${user?.lastname}`
  const donorPhoneNumber = user?.phone
  const donorEmail = user?.email

  const payload = {
    pledgeType,
    amount,
    isMonthly,
    organizationId,
    transactionType,
    donorId,
    donorName,
    donorPhoneNumber,
    donorEmail,
  }
  if (isEventPledge) {
    payload.eventId = event?.Id
    payload.eventName = event?.Title
    payload.PledgeReminders = event?.PledgeReminders
    payload.MessagingFrequency = event?.MessagingFrequency
    payload.MaximumMessages = event?.MaximumMessages
  } else {
    payload.PledgeReminders = 'Manual'
  }
  const response = await Api.post('/pledge', payload)
  return response
}

export const membershipProgramPayment = async (payload, organizationId) => {
  const response = makeRequest(
    'post',
    '/component/membership-program/credit-card-payment',
    { organization: organizationId },
    payload,
  )
  return response
}

export const sendContactUsForm = async (data) => {
  const response = await Api.post('/admin/contact-us', data)
  return response
}

export const validateOrgAddress = async (data) => {
  const response = await Api.post('/org/validate_address', data)
  return response
}

export const validateOrgUrl = async (url, userId) => {
  const response = await Api.post('/org/validate_url', { url, userId })
  return response.results
}

export const fetchUserDetail = (action) => (dispatch) => {
  dispatch(authActions.setLoading(true))
  Auth.currentSession({ bypassCache: true })
    .then((actualUser) => {
      const roles = actualUser?.accessToken?.payload['cognito:groups']
      Auth.currentAuthenticatedUser({ bypassCache: true })
        .then((res) => {
          const userDetail = {
            id: res.attributes.sub,
            firstname: res.attributes.given_name,
            lastname: res.attributes.family_name,
            email: res.attributes.email,
            phone: res.attributes.phone_number,
            email_verified: res.attributes.email_verified,
            phone_number_verified: res.attributes.phone_number_verified,
            roles,
          }
          dispatch(authActions.setUser(userDetail))
          // dispatch(getOrganization(userDetail.email))
          if (action) {
            action()
          }
        })
        .finally(() => dispatch(authActions.setLoading(false)))
    })
    .catch((error) => {
      if (error === 'No current user') {
        message.warning('Please login to proceed')
        dispatch(logout())
      } else {
        message.error('Unable to fetch user details')
      }
    })
    .finally(() => dispatch(authActions.setLoading(false)))
}

export const getPaymentErrors = (data) => (dispatch) => {
  dispatch(authActions.setLoading(true))
  Api.get('/admin/payment-errors', data)
    .then((res) => {
      dispatch(authActions.setPaymentErrors(res.results))
      dispatch(authActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(authActions.setLoading(false))
    })
}

export const getDonorFeedbacks = (data) => (dispatch) => {
  dispatch(authActions.setLoading(true))
  Api.get('/admin/get-feedbacks', data)
    .then((res) => {
      dispatch(authActions.setDonorFeedbacks(res.data))
      dispatch(authActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(authActions.setLoading(false))
    })
}

export const getUserLogs = (data) => (dispatch) => {
  dispatch(authActions.setLoading(true))
  Api.get('/admin/user-logs', data)
    .then((res) => {
      dispatch(authActions.setUserLogs(res.results))
      dispatch(authActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(authActions.setLoading(false))
    })
}
export const downloadQRCodePDF = async (payload, dispatch) => {
  try {
    dispatch(authActions.setLoading(true))

    const response = await Api.post('/presenter/downloadQRcodes', payload)

    dispatch(authActions.setDownloadable(response))
    dispatch(authActions.setLoading(false))

    return response
  } catch (error) {
    console.log('Error:', error)
    dispatch(authActions.setLoading(false))
  }
}

export const downloadOrganizationDonationQRCodePDF = async (payload) => {
  const response = await Api.post(
    '/presenter/downloadOrganizationDonationQRCode',
    payload,
  )
  return response
}
