import React, { useEffect, useState } from 'react'
import {
  Modal,
  Form,
  Col,
  Row,
  Button,
  Input,
  Typography,
  Popconfirm,
  Tag,
  message,
  DatePicker,
} from 'antd'
import * as Yup from 'yup'
import { formatAmount, formateDate } from 'utils'
import {
  changeReservationAction,
  reservationAction,
} from 'store/roomSlice/roomActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { GrRevert } from 'react-icons/gr'
import { IoMdCopy } from 'react-icons/io'
import moment from 'moment'

const { Title } = Typography

const schema = Yup.object().shape({
  RoomName: Yup.string().required('Room name is required'),
  DonorName: Yup.string().required('Donor name is required'),
  Cost: Yup.string().required().typeError('Cost is required'),
  UpdatedCost: Yup.string().typeError('Updated cost is required'),
})

const reservationValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {}

const disabledDates = (d) => !d || d.isBefore(moment())

const ConfirmationModal = ({ refresh, visible, setVisible, reservation }) => {
  const [loading, setLoading] = useState(false)
  const [isChange, setChange] = useState(false)
  const [newDate, setNewDate] = useState(null)
  const [uniqueDates, setUniqueDates] = useState([])
  const [form] = Form.useForm()
  const { organization } = useAuthSelector()
  Form.useWatch('UpdatedCost', form)

  const UpdatedCost = form.getFieldValue('UpdatedCost')

  const onTakeAction = async (type) => {
    setLoading(true)
    try {
      const payload = {
        Id: reservation?.Id,
        reservation,
        organization,
        UpdatedCost: UpdatedCost || reservation?.TotalCost,
        AcceptanceStatus: type,
        CreatorPhoneNumber: reservation?.CreatorPhoneNumber,
      }
      await reservationAction(payload)
      setVisible(!visible)
      message.success('Action taken successfully')
      setLoading(false)
      refresh()
    } catch (error) {
      message.error('Error taking action on reservation')
      setLoading(false)
    }
  }

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value)
    message.success('Copied to Clipboard')
  }

  const onChangeReservation = async () => {
    setLoading(true)
    try {
      let payload = null
      if (newDate) {
        payload = {
          Id: reservation?.Id,
          changedDate: newDate,
          newDates: getDates(),
          reservation,
          organization,
          AcceptanceStatus: 'ChangeRequested',
        }
      } else {
        payload = {
          Id: reservation?.Id,
          changedDate: newDate,
          newDates: [],
          reservation,
          organization,
          AcceptanceStatus: 'ChangeRequested',
        }
      }
      await changeReservationAction(payload)
      setVisible(!visible)
      message.success('Reservation date changed successfully')
      setLoading(false)
      refresh()
    } catch (error) {
      message.error('Error taking action on reservation')
      setLoading(false)
    }
  }

  const getDates = () => {
    const dates = []
    if (newDate) {
      if (reservation?.IsRecurring) {
        const currentDate = moment(newDate)
        if (reservation?.RecurringType === 'Daily') {
          for (let i = 0; i < reservation.RecurringFrequency; i += 1) {
            dates.push(moment(currentDate.add(1, 'days')).format())
          }
        }
        if (reservation?.RecurringType === 'Weekly') {
          for (let i = 0; i < reservation.RecurringFrequency; i += 1) {
            dates.push(moment(currentDate.add(1, 'weeks')).format())
          }
        }
        if (reservation?.RecurringType === 'Monthly') {
          for (let i = 0; i < reservation.RecurringFrequency; i += 1) {
            dates.push(moment(currentDate.add(1, 'months')).format())
          }
        }
        if (reservation?.RecurringType === 'Quaterly') {
          for (let i = 0; i < reservation.RecurringFrequency; i += 1) {
            dates.push(moment(currentDate.add(1, 'quarters')).format())
          }
        }
        if (reservation?.RecurringType === 'Yearly') {
          for (let i = 0; i < reservation.RecurringFrequency; i += 1) {
            dates.push(moment(currentDate.add(1, 'years')).format())
          }
        }
      }
      const totalDates = [new Date(newDate), ...dates]
      return totalDates
    }
    return reservation.RecurringDates
  }

  useEffect(() => {
    if (reservation?.RecurringDates) {
      const seen = {}
      const uniqueArray = reservation.RecurringDates.filter((date) => {
        const dateString = new Date(date).toISOString()
        if (!seen[dateString]) {
          seen[dateString] = true
          return true
        }
        return false
      })

      setUniqueDates(uniqueArray)
    }
  }, [reservation?.RecurringDates])

  return (
    <Modal
      afterClose={() => {
        setChange(false)
        setNewDate(null)
      }}
      centered
      title="Take Action"
      open={visible}
      onCancel={setVisible}
      destroyOnClose
      width={400}
      okText="Donate"
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        key={0}
        name="room"
        initialValues={initialValues}
        scrollToFirstError
      >
        <Row gutter={[24, 0]}>
          <Col xl={24} md={24} xs={24} sm={24}>
            {!isChange && (
              <>
                {reservation?.IsRecurring ? (
                  <div className="t-flex t-flex-col">
                    <div className="t-flex t-w-full t-justify-end">
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => setChange(!isChange)}
                      >
                        Change Date
                      </Button>
                    </div>
                    <p className="t-text-secondary-100 t-mb-4 t-font-bold">
                      This room is reserved recurring on following dates
                    </p>
                    <div>
                      {uniqueDates?.map((item, index) => (
                        <Tag key={index} color="orange" className="t-my-1">
                          {formateDate(item)}
                        </Tag>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="t-flex t-w-full t-justify-end">
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => setChange(!isChange)}
                      >
                        Change Date
                      </Button>
                    </div>
                    <div className="t-flex t-justify-center t-items-center t-text-secondary-100 t-mb-4 t-text-lg">
                      Reservation date is{'  '}
                      <span className="t-text-secondary-100 t-font-bold t-text-lg t-pl-2">
                        {formateDate(reservation?.Date)}
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}
          </Col>
          {isChange && (
            <Col xl={24} md={24} xs={24} sm={24}>
              <div className="t-flex t-w-full t-justify-end">
                <GrRevert
                  fontSize="1.5rem"
                  className="t-text-secondary-100 t-cursor-pointer"
                  onClick={() => {
                    setChange(!isChange)
                    setNewDate(null)
                  }}
                />
              </div>
              <Form.Item
                name="Change Reservation Date"
                label="Select Date"
                colon={false}
                value={newDate}
                className="t-text-secondary-100"
              >
                <DatePicker
                  defaultValue={newDate || moment(reservation?.Date)}
                  onChange={(e) => setNewDate(e)}
                  format="MM-DD-YYYY"
                  className="t-w-full"
                  disabledDate={disabledDates}
                />
              </Form.Item>
            </Col>
          )}
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="RoomName"
              label="Room Name"
              colon={false}
              rules={[reservationValidation]}
            >
              <Input disabled placeholder={reservation?.RoomName} />
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="DonorName"
              label="Donor Name"
              colon={false}
              rules={[reservationValidation]}
            >
              <Input disabled placeholder={reservation?.CreatorName} />
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <div className="t-flex t-justify-end">
              <IoMdCopy
                fontSize="1.6rem"
                className="t-text-secondary-100 t-cursor-pointer"
                onClick={() => copyToClipboard(reservation?.CreatorEmail)}
              />
            </div>
            <Form.Item
              name="DonorEmail"
              label="Donor Email"
              colon={false}
              rules={[reservationValidation]}
            >
              <Input disabled placeholder={reservation?.CreatorEmail} />
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <div className="t-flex t-justify-end">
              <IoMdCopy
                fontSize="1.6rem"
                className="t-text-secondary-100 t-cursor-pointer"
                onClick={() => copyToClipboard(reservation?.CreatorPhoneNumber)}
              />
            </div>
            <Form.Item
              name="DonorPhoneNumber"
              label="Donor Phone Number"
              colon={false}
              rules={[reservationValidation]}
            >
              <Input disabled placeholder={reservation?.CreatorPhoneNumber} />
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="Cost"
              label="Total Cost"
              colon={false}
              rules={[reservationValidation]}
            >
              <Input prefix="$" disabled placeholder={reservation?.TotalCost} />
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <h1 className="t-font-bold t-text-secondary-100">Durations</h1>
            <div className="t-flex t-flex-wrap t-my-2">
              {reservation?.Durations?.map((item) => (
                <Tag color="green">{`${item?.from} - ${item?.to}`}</Tag>
              ))}
            </div>
          </Col>
          {isChange && (
            <>
              {reservation.IsRecurring ? (
                <Col xl={24} md={24} xs={24} sm={24}>
                  <h1 className="t-font-bold t-text-secondary-100">
                    Recurring dates are
                  </h1>
                  <div className="t-flex t-flex-wrap t-my-2">
                    {getDates().map((item) => (
                      <Tag color="orange" className="t-my-1">
                        {formateDate(moment(item))}
                      </Tag>
                    ))}
                  </div>
                </Col>
              ) : (
                <Col xl={24} md={24} xs={24} sm={24}>
                  <h1 className="t-font-bold t-text-secondary-100 t-pr-2">
                    Changed Reservation Date
                  </h1>
                  <div className="t-flex t-flex-wrap t-my-2">
                    {`New changed date is ${formateDate(moment(newDate))}`}
                  </div>
                </Col>
              )}
            </>
          )}
          {!isChange && (
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="UpdatedCost"
                label="Override Total Due"
                colon={false}
                rules={[reservationValidation]}
              >
                <Input
                  placeholder="Override Total Due"
                  inputMode="numeric"
                  prefix="$"
                  onChange={(e) =>
                    form.setFieldsValue({
                      UpdatedCost: e.target.value.replace(/\D/g, ''),
                    })
                  }
                />
              </Form.Item>
            </Col>
          )}
          <Col xl={24} md={24} xs={24} sm={24}>
            <Row justify="end">
              <Title
                level={5}
                className="t-text-secondary-100 t-font-bold"
              >{`Reservation Cost Will be = ${
                UpdatedCost
                  ? formatAmount(UpdatedCost)
                  : formatAmount(reservation?.TotalCost)
              }`}</Title>
            </Row>
          </Col>
          {loading ? (
            <Col xl={24} md={24} xs={24} sm={24} className="t-w-full">
              <Button
                type="primary"
                size="large"
                className="t-w-full"
                loading={loading}
              >
                Loading
              </Button>
            </Col>
          ) : (
            <>
              {isChange ? (
                <Col span={24}>
                  <Button
                    type="primary"
                    size="large"
                    loading={loading}
                    className="t-bg-primary-100 t-border-none t-w-full"
                    onClick={onChangeReservation}
                  >
                    Change Reservation
                  </Button>
                </Col>
              ) : (
                <>
                  <Col xl={12} md={12} xs={24} sm={24}>
                    <Popconfirm
                      title="Are you sure you want to cancel the reservation？"
                      okText="Reject the reservation?"
                      cancelText="No"
                      placement="left"
                      onConfirm={() => onTakeAction('Rejected')}
                    >
                      <Button
                        type="primary"
                        size="large"
                        className="t-bg-red-500 t-border-none t-w-full"
                      >
                        Reject
                      </Button>
                    </Popconfirm>
                  </Col>
                  <Col xl={12} md={12} xs={24} sm={24}>
                    <Button
                      type="primary"
                      size="large"
                      className="t-bg-green-500 t-border-none t-w-full"
                      onClick={() => onTakeAction('Accepted')}
                    >
                      Accept
                    </Button>
                  </Col>
                </>
              )}
            </>
          )}
        </Row>
      </Form>
    </Modal>
  )
}

export { ConfirmationModal }
