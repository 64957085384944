import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import {
  Auth,
  Error404,
  Splash,
  Donate,
  PaypalSubmission,
  Request,
  ClassRequest,
  RecurringDonor,
  OrganizationDonate,
  SeedDonationsPayments,
  VolunteerRegisteration,
  ServiceRegisteration,
  ProgramRegisteration,
  BusinessServicePayment,
  RentalRoomPayment,
} from 'screens'
import { Main } from 'components/layouts'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { UnauthenticatedBusinessProfile } from 'screens/businessProfile'
// import { UnauthenticatedRoomRental } from 'screens/unauthenticatedRoomRental'
import { StripeConnect } from 'screens/stripeConnect'
import { Pledge, PledgeConversion } from 'screens/unAuthenticated'
import { useRoles } from 'hooks'
import { Downloadable } from 'screens/main/Downloadable/Downloadable'
import { OrganizationRegister } from 'screens/organizationRegistration/OrganizationRegister'
// import { OrganizationRegistrationV2 } from 'screens/OrganizationRegisterV2/OrganizationRegistrationV2'
import { config } from 'configs'

export const AppRoutes = () => {
  const { user } = useAuthSelector()
  const { isDonor, isSubscriber, isAdmin } = useRoles()

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/stripe-connect" exact component={StripeConnect} />
        <Route
          path="/:type/paypalsubmission"
          exact
          component={PaypalSubmission}
        />
        <Route
          path="/business-profile/:id"
          exact
          component={UnauthenticatedBusinessProfile}
        />
        {/* <Route
          path="/room-rental-view/:id"
          exact
          component={UnauthenticatedRoomRental}
        /> */}
        <Route path="/donation/:id" exact component={Donate} />
        <Route path="/donate/:id" exact component={OrganizationDonate} />
        <Route path="/recurring-donor/:id" exact component={RecurringDonor} />
        <Route
          path="/seed-donation/:id"
          exact
          component={SeedDonationsPayments}
        />
        <Route path="/pledge/:type/:id" exact component={Pledge} />
        <Route
          path="/pledge-conversion/:id"
          exact
          component={PledgeConversion}
        />
        <Route
          path="/registration/volunteer/:id"
          exact
          component={VolunteerRegisteration}
        />
        <Route
          path="/setting/registration"
          exact
          component={OrganizationRegister}
        />
        {/* <Route
          path="/settings/register-organization-v2"
          exact
          component={OrganizationRegistrationV2}
        /> */}
        <Route
          path="/registration/service/:id"
          exact
          component={ServiceRegisteration}
        />
        <Route
          path="/registration/program/:id"
          exact
          component={ProgramRegisteration}
        />
        <Route
          path="/payment/business-service/:id"
          exact
          component={BusinessServicePayment}
        />
        <Route path="/payment/room/:id" exact component={RentalRoomPayment} />
        <Route path="/request/:status/:id" exact component={Request} />
        <Route
          path="/classProgram/:status/:id"
          exact
          component={ClassRequest}
        />
        <Route path="/download/:type" component={Downloadable} />
        <Route path="/auth" exact component={Auth} />
        <Route path="/welcome" exact component={Splash} />
        <Route path="/404" exact component={Error404} />
        <Route path="/:id" component={Main} />
        {user ? (
          <Redirect
            to={{
              pathname: isAdmin
                ? config.defaultRedirect.admin
                : isSubscriber
                ? config.defaultRedirect.subscriber
                : isDonor
                ? config.defaultRedirect.donor
                : config.defaultRedirect.donor,
            }}
          />
        ) : (
          <Redirect to={{ pathname: '/auth' }} />
        )}
      </Switch>
    </BrowserRouter>
  )
}
