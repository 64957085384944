/* eslint-disable */
import React, { useState, useEffect } from 'react'
import {
  Button,
  Card,
  Form,
  Row,
  Col,
  Typography,
  Skeleton,
  message,
  Input,
  Select,
  Tooltip,
  Divider,
  Checkbox,
} from 'antd'
import * as Yup from 'yup'
import {
  formatAmount,
  formateDate,
  createFrequencyNumbers,
  getHoursFromDurations,
  getEstimatedProcessingFee,
} from 'utils'
import { IoIosArrowBack } from 'react-icons/io'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import {
  acceptChangeRequest,
  checkRoomAvailability,
  getReservation,
  getRoom,
  requestReservation,
  updateReservation,
} from 'store/roomSlice/roomActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useLocation } from 'react-router-dom'
import { BiBlock } from 'react-icons/bi'
import {
  RoomNotFound,
  RoomDetail,
  ReservationDetail,
  SelectableCalender,
  Resolver,
} from './components'
import { IoLocationOutline } from 'react-icons/io5'
import { HiOutlineCalendar } from 'react-icons/hi'
import { FaRegClock } from 'react-icons/fa'
import { TbDisabled } from 'react-icons/tb'
import { Confirmation } from 'components/common'

const { Title, Text } = Typography

const recurringTypes = ['Daily', 'Weekly', 'Monthly', 'Quaterly', 'Yearly']

const stringRegex = /^[A-Za-z ]+$/
const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
const schema = Yup.object().shape({
  IsRecurring: Yup.boolean(),
  RecurringType: Yup.string().required('Recurring type is required'),
  RecurringFrequency: Yup.string().required('Recurring frequency is required'),
  HoursNeeded: Yup.string(),
  UserName: Yup.string()
    .required('Required')
    .matches(stringRegex, 'Invalid Name'),
  UserEmail: Yup.string()
    .required('Required')
    .matches(emailRegex, 'Invalid Email'),
})

const roomValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  IsRecurring: false,
  RecurringType: 'Daily',
  RecurringFrequency: null,
  HoursNeeded: null,
  UserName: '',
  UserEmail: '',
}

const RentalReservation = ({ unauthenticated }) => {
  const history = useHistory()
  const { search, state } = useLocation()
  const { user } = useAuthSelector()
  const { id } = useParams()

  const query = new URLSearchParams(search)
  const reservationId = query.get('reservationId')

  const [hoursNeededFilled, setHoursFilled] = useState(false)
  const [durationsSelected, setSelectedDurations] = useState([])
  const [selectedDate, setSelectedDate] = useState(moment().add(1, 'day'))
  const [customDurations, setCustomDurations] = useState([])
  const [room, setRoom] = useState(null)
  const [reservation, setReservation] = useState(null)
  const [isUpdate, setUpdate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [onSaveLoading, setSaveLoading] = useState(false)
  const [isFetchingData, setFetchingData] = useState(false)
  const [dayDuration, setDayDuration] = useState(null)
  const [alreadyReservedDurations, setAlreadyReservedDurations] = useState([])

  const [recurringDates, setRecurringDates] = useState([])
  const [sentForApproval, setSentForApproval] = useState(false)

  const [isModal, setModal] = useState(false)
  const [page, setPage] = useState(1)
  const [questions, setQuestions] = useState([])

  const [form] = Form.useForm()

  Form.useWatch('RecurringType', form)
  Form.useWatch('IsRecurring', form)
  Form.useWatch('RecurringFrequency', form)
  Form.useWatch('HoursNeeded', form)

  const recurringFrequency = form.getFieldValue('RecurringFrequency')
  const frequencyType = form.getFieldValue('RecurringType')
  const isRecurring = form.getFieldValue('IsRecurring')
  const hoursNeeded = form.getFieldValue('HoursNeeded')

  const isWeeklyFrequency = form.getFieldValue('RecurringType') === 'Weekly'
  const isMonthlyFrequency = form.getFieldValue('RecurringType') === 'Monthly'
  const isQuaterlyFrequency = form.getFieldValue('RecurringType') === 'Quaterly'
  const isYearlyFrequency = form.getFieldValue('RecurringType') === 'Yearly'
  const isDailyFrequency = form.getFieldValue('RecurringType') === 'Daily'

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetchingData(true)
        const roomDetail = await getRoom(id)
        setRoom(roomDetail)
        if (!reservationId) {
          setQuestions(roomDetail?.Question)
        }
        if (reservationId) {
          const reservationDetail = await getReservation(reservationId)
          setReservation(reservationDetail)
          setQuestions(reservationDetail?.Question)
        }
        setFetchingData(false)
      } catch (error) {
        setFetchingData(false)
      }
    }
    if (id) {
      fetchData()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (room) {
      onDateChange(selectedDate)
    }
  }, [room])

  useEffect(() => {
    if (!isRecurring) {
      form.setFieldValue('RecurringFrequency', null)
    }
  }, [isRecurring])

  useEffect(() => {
    setRecurringDates([
      {
        date: selectedDate.format(),
        isAvailable: checkIfDayIsAvailable(selectedDate.format()),
      },
      ...getDatesWithAvailability(),
    ])
    // eslint-disable-next-line
  }, [
    form.getFieldValue('RecurringType'),
    form.getFieldValue('IsRecurring'),
    form.getFieldValue('RecurringFrequency'),
    selectedDate,
  ])

  const onClickNext = () => {
    if (parseInt(hoursNeeded) > parseInt(room?.MaximumReservationHours)) {
      message.error(
        `Maximum of ${room?.MaximumReservationHours} hours can be booked for this room in a day`,
      )
      setHoursFilled(false)
    } else if (hoursNeeded) {
      setHoursFilled(true)
      addPage()
    } else {
      clearFormValuesOnDateChange()
      setSelectedDate(moment().add(1, 'day'))
      form.setFieldsValue({
        IsRecurring: false,
        RecurringType: 'Daily',
        RecurringFrequency: null,
      })
      message.destroy()
      message.error('Kindly enter number of hours needed for reservation')
      setHoursFilled(false)
    }
  }

  const clearFormValuesOnDateChange = () => {
    setSelectedDurations([])
    setCustomDurations([])
  }

  const onDateChange = (dateSelected) => {
    if (!moment(selectedDate).isBefore(moment())) {
      const day = moment(dateSelected).format('dddd').toLowerCase()
      let dayDurations = room?.Schedule[day]
      const updatedDurations = room?.Schedule[day].durations.map(
        (item, index) => ({
          ...item,
          Id: index + 1,
        }),
      )
      dayDurations = { ...dayDurations, durations: updatedDurations }
      setDayDuration(dayDurations)
      clearFormValuesOnDateChange()
    }
  }

  const goBackButtonClick = () => {
    if (state && state.urlData) {
      history.push({
        pathname: state.urlData.pathname,
        state: { key: state.urlData.activeKey },
      })
    } else {
      history.goBack()
    }
  }
  const [btnDisable, setbtnDisable] = useState(false)
  const onCustomDurationChange = (durations) => {
    setCustomDurations([...durations])
    const hasOverlap = isAnyOverlap(durations, alreadyReservedDurations)
    setbtnDisable(hasOverlap)
  }

  const getDates = () => {
    let dates = []
    if (recurringFrequency) {
      const currentDate = moment(selectedDate)
      if (isDailyFrequency) {
        for (let i = 0; i < recurringFrequency; i++) {
          dates.push(moment(currentDate.add(1, 'days')).format())
        }
      }
      if (isWeeklyFrequency) {
        for (let i = 0; i < recurringFrequency; i++) {
          dates.push(moment(currentDate.add(1, 'weeks')).format())
        }
      }
      if (isMonthlyFrequency) {
        for (let i = 0; i < recurringFrequency; i++) {
          dates.push(moment(currentDate.add(1, 'months')).format())
        }
      }
      if (isQuaterlyFrequency) {
        for (let i = 0; i < recurringFrequency; i++) {
          dates.push(moment(currentDate.add(1, 'quarters')).format())
        }
      }
      if (isYearlyFrequency) {
        for (let i = 0; i < recurringFrequency; i++) {
          dates.push(moment(currentDate.add(1, 'years')).format())
        }
      }
    }
    return dates
  }

  const checkIfDayIsAvailable = (dateSelected) => {
    const day = moment(dateSelected).format('dddd').toLowerCase()
    return room?.Schedule[day].open
  }

  const getDatesWithAvailability = () => {
    let dates = []
    if (recurringFrequency) {
      const currentDate = moment(selectedDate)
      if (isDailyFrequency) {
        for (let i = 0; i < recurringFrequency; i++) {
          const newDate = moment(currentDate.add(1, 'days')).format()
          dates.push({
            date: newDate,
            isAvailable: checkIfDayIsAvailable(newDate),
          })
        }
      }
      if (isWeeklyFrequency) {
        for (let i = 0; i < recurringFrequency; i++) {
          const newDate = moment(currentDate.add(1, 'weeks')).format()
          dates.push({
            date: newDate,
            isAvailable: checkIfDayIsAvailable(newDate),
          })
        }
      }
      if (isMonthlyFrequency) {
        for (let i = 0; i < recurringFrequency; i++) {
          const newDate = moment(currentDate.add(1, 'months')).format()
          dates.push({
            date: newDate,
            isAvailable: checkIfDayIsAvailable(newDate),
          })
        }
      }
      if (isQuaterlyFrequency) {
        for (let i = 0; i < recurringFrequency; i++) {
          const newDate = moment(currentDate.add(1, 'quarters')).format()
          dates.push({
            date: newDate,
            isAvailable: checkIfDayIsAvailable(newDate),
          })
        }
      }
      if (isYearlyFrequency) {
        for (let i = 0; i < recurringFrequency; i++) {
          const newDate = moment(currentDate.add(1, 'years')).format()
          dates.push({
            date: newDate,
            isAvailable: checkIfDayIsAvailable(newDate),
          })
        }
      }
    }
    return dates
  }

  const onFinish = async () => {
    const userName = form.getFieldValue('UserName')
    const userEmail = form.getFieldValue('UserEmail')
    const isRecurring = form.getFieldValue('IsRecurring')
    const recurringType = form.getFieldValue('RecurringType')
    const recurringFrequency = form.getFieldValue('RecurringFrequency')

    const hours = getTotalHoursBooked()
    let selectedDurations = []
    if (customDurations?.length > 0) {
      selectedDurations = customDurations
    } else {
      const allDayDurations = dayDuration?.durations
      selectedDurations = allDayDurations?.filter((item) =>
        durationsSelected.includes(item?.Id),
      )
      selectedDurations = selectedDurations.map((item) => {
        const updatedItem = item
        delete updatedItem?.Id
        return { ...updatedItem }
      })
    }
    try {
      setLoading(true)
      let payload = {}
      const newDates = recurringDates
        .slice(1)
        .map((item) => new Date(item.date))
      const myrecurringdates = [selectedDate.format(), ...newDates]

      if (isUpdate && reservationId) {
        payload = {
          Id: reservationId,
          Date: new Date(selectedDate),
          TotalHours: hours,
          TotalCost: hours * room?.PerHourRate,
          AcceptanceStatus: 'Pending',
          Durations: selectedDurations,
          IsRecurring: isRecurring || false,
          RecurringType: isRecurring ? recurringType : null,
          RecurringFrequency: isRecurring ? recurringFrequency : 0,
          RoomCreatorEmail: room?.CreatorEmail,
          RoomName: room?.Name,
          RecurringDates: isRecurring ? myrecurringdates : [],
          Questions: questions,
        }
        await updateReservation(payload)
        goToRentals()
        setLoading(false)
        message.success('Reservation request updated')
      } else {
        payload = {
          Date: selectedDate,
          RoomId: room?.Id,
          CreatorId: unauthenticated ? null : user?.id,
          CreatorEmail: unauthenticated ? userEmail : user?.email,
          CreatorPhoneNumber: unauthenticated ? null : user?.phone,
          CreatorName: unauthenticated
            ? userName
            : `${user?.firstname} ${user?.lastname}`,
          OrganizationId: room?.OrganizationId,
          TotalHours: hours,
          TotalCost: hours * room.PerHourRate,
          AcceptanceStatus: 'Pending',
          Durations: selectedDurations,
          IsRecurring: isRecurring,
          RecurringType: isRecurring ? recurringType : null,
          RecurringFrequency: isRecurring ? recurringFrequency : 0,
          RoomCreatorEmail: room?.CreatorEmail,
          RoomName: room?.Name,
          RecurringDates: isRecurring ? myrecurringdates : [],
          NeedApproval: room?.NeedApproval,
          Questions: questions,
        }
        const requestedReservationId = await requestReservation(payload)
        if (room?.NeedApproval) {
          message.success('Reservation request sent')
          setSentForApproval(true)
          // history.push('/settings/account?key=3')
          // goToRentals()
        } else {
          history.push(`/payment/room/${requestedReservationId}`)
          // message.success('Reservation request created. Pay to proceed further')
          // if (unauthenticated) {
          //   history.goBack()
          //   setFetchingData(true)
          //   if (reservationId) {
          //     const reservationDetail = await getReservation(reservationId)
          //     setReservation(reservationDetail)
          //   }
          //   setFetchingData(false)
          // } else {
          //   history.push('/settings/account?key=3')
          // }
        }
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      message.error('Error requesting reservation')
    }
  }

  const goToRentals = () => {
    if (unauthenticated) {
      history.goBack()
    } else {
      history.push('/settings/account?key=3')
    }
  }

  const getTotalHoursBooked = () => {
    if (customDurations?.length) {
      if (isRecurring) {
        return getHoursFromDurations(customDurations) * recurringDates?.length
      } else {
        return getHoursFromDurations(customDurations)
      }
    } else {
      const allDayDurations = dayDuration?.durations
      const selectedDurations = allDayDurations?.filter((item) =>
        durationsSelected.includes(item?.Id),
      )
      if (isRecurring) {
        return getHoursFromDurations(selectedDurations) * recurringDates?.length
      } else {
        return getHoursFromDurations(selectedDurations)
      }
    }
  }

  const getTotalHoursBookedForOneDay = () => {
    if (customDurations?.length) {
      return getHoursFromDurations(customDurations)
    } else {
      const allDayDurations = dayDuration?.durations
      const selectedDurations = allDayDurations?.filter((item) =>
        durationsSelected.includes(item?.Id),
      )
      return getHoursFromDurations(selectedDurations)
    }
  }

  const onSave = async () => {
    try {
      setSaveLoading(true)
      await acceptChangeRequest(reservation)
      message.success(
        'Reservation change request accepted. Pay to confirm your booking',
      )
      history.push('/settings/account?key=3')
      setSaveLoading(false)
    } catch (error) {
      setSaveLoading(false)
      message.error('Error saving the reservation')
    }
  }

  const isCustomDurations = customDurations?.length > 0
  const isClickedUpdate = reservation && !isUpdate
  const isConflictingAvailability =
    recurringDates.filter((item) => !item.isAvailable)?.length > 0
  const isDisable =
    getTotalHoursBookedForOneDay() <= 0 ||
    getTotalHoursBookedForOneDay() > room.MaximumReservationHours ||
    isConflictingAvailability
  const isHoursInNegative = getTotalHoursBookedForOneDay() < 0

  // Todo we need to improve this logic
  const areAllTimeSlotsAvailable = (dayDuration, customDurations, nonStop) => {
    if (nonStop) {
      return true
    }
    for (const customDuration of customDurations) {
      const requestedFrom = moment(customDuration.from, 'h:mm A')
      const requestedTo = moment(customDuration.to, 'h:mm A')
      let isAvailable = false

      for (const slot of dayDuration) {
        const availableFrom = moment(slot.from, 'h:mm A')
        const availableTo = moment(slot.to, 'h:mm A')

        if (
          requestedFrom.isSameOrAfter(availableFrom) &&
          requestedTo.isSameOrBefore(availableTo)
        ) {
          isAvailable = true
          break
        }
      }

      if (!isAvailable) {
        return false
      }
    }

    return true
  }

  // const allSlotsAvailable = areAllTimeSlotsAvailable(
  //   dayDuration?.durations,
  //   customDurations,
  //   dayDuration?.nonstop,
  // )
  const allSlotsAvailable = true

  useEffect(() => {
    const hasOverlap = isAnyOverlap(customDurations, alreadyReservedDurations)
    setbtnDisable(hasOverlap)
  }, [customDurations, alreadyReservedDurations])

  const isTimeOverlap = (time1, time2) => {
    const format = 'hh:mm A'
    const start1 = moment(time1.from, format)
    const end1 = moment(time1.to, format)
    const start2 = moment(time2.from, format)
    const end2 = moment(time2.to, format)

    return start1.isBefore(end2) && end1.isAfter(start2)
  }

  const isAnyOverlap = (selectedDurations, reservedDurations) => {
    for (let i = 0; i < selectedDurations?.length; i++) {
      for (let j = 0; j < reservedDurations?.length; j++) {
        if (isTimeOverlap(selectedDurations[i], reservedDurations[j])) {
          return true
        }
      }
    }
    return false
  }

  const checkQuestion = (questionsObj) => {
    if (!questionsObj || questionsObj?.length === 0) {
      return null
    }
    const invalidQuestions = []
    questionsObj?.forEach((data, index) => {
      if (data?.required && (!data?.answer || data?.answer?.length <= 0)) {
        invalidQuestions.push(index + 1)
      }
    })

    return invalidQuestions?.length > 0 ? invalidQuestions : null
  }

  const isDayOpen = dayDuration?.open
  const isDayNonStop = dayDuration?.nonstop
  const isDayHasNoDurations = dayDuration?.durations?.length === 0
  const isRoomClosed = !isDayOpen || (isDayHasNoDurations && !isDayNonStop)

  const addPage = () => {
    if (
      (page === 2 && !allSlotsAvailable) ||
      (page === 2 && isRoomClosed) ||
      (page === 2 && btnDisable) ||
      (page === 2 && isDisable)
    ) {
      message.destroy()
      message.error(`You Selected wrong time duration`)
      return
    }
    if (page === 3) {
      const invalidQuestions = checkQuestion(questions)
      if (invalidQuestions) {
        const invalidQuestionNumbers = invalidQuestions.join(', ')
        message.destroy()
        message.error(
          `Please complete the following questions: ${invalidQuestionNumbers}`,
        )
        return
      }
    }
    setPage((prevPage) => prevPage + 1)
  }

  const handleBack = () => {
    if (page !== 1) setPage((prevPage) => prevPage - 1)
  }

  const takeProcessingCharges = (cost, mark) => {
    return formatAmount(getEstimatedProcessingFee(cost, mark))
  }

  return !sentForApproval ? (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <div className="t-flex t-w-full t-justify-between">
          <div>
            <h1 className="mb-0 t-text-3xl">{room?.Name}</h1>
            <div className="t-flex t-flex-row t-mt-4">
              <IoLocationOutline />
              <p className="font-regular t-mx-2">{room?.OrganizationName}</p>
            </div>
          </div>
          <div className="t-flex t-items-center t-space-x-4 t-mb-2">
            <Button
              type="primary"
              className="t-rounded-full t-flex t-items-center"
              onClick={goBackButtonClick}
              size="small"
            >
              <IoIosArrowBack />
              Back
            </Button>
          </div>
        </div>,
      ]}
    >
      <Skeleton loading={isFetchingData} active paragraph={{ rows: 18 }}>
        {room ? (
          <Form
            form={form}
            layout="vertical"
            key={0}
            onFinish={onFinish}
            name="reservation"
            initialValues={initialValues}
            scrollToFirstError
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault() // Prevent default form submission behavior
              }
            }}
          >
            <Row gutter={[24, 0]}>
              <Col xl={12} md={12} xs={24} sm={24} className="t-my-1">
                <RoomDetail room={room} />
              </Col>
              <Col xl={12} md={12} xs={24} sm={24} className="t-my-2">
                <Card>
                  <Row>
                    <div className="t-flex t-flex-col t-w-full">
                      <Title level={5}>
                        {page === 1 || page === 2 ? (
                          <>
                            <Text className="t-font-bold t-text-2xl">
                              {formatAmount(room?.PerHourRate)}
                            </Text>{' '}
                            <Text className="t-font-normal">/Hour</Text>
                          </>
                        ) : page === 3 ? (
                          <Text className="t-font-bold t-text-2xl">
                            Questions
                          </Text>
                        ) : (
                          page === 4 && null
                        )}
                      </Title>
                      {page !== 4 && <Divider type="horizontal" />}
                      <div className="t-flex t-flex-row t-justify-between t-gap-1 t-w-full t-mb-8">
                        <div className="t-flex t-space-x-4 t-mb-2 t-mt-[-12px]">
                          <Button
                            className="t-rounded-full t-flex t-items-center t-border-none t-shadow-none"
                            onClick={handleBack}
                            size="medium"
                          >
                            <IoIosArrowBack />
                            Back
                          </Button>
                        </div>
                        <div className="t-flex t-w-1/2">
                          <div
                            className={`${
                              page >= 1 ? 't-bg-[#2e97de]' : 't-bg-[#e5e7e9]'
                            } t-w-[100%] t-h-2 t-rounded t-mx-[2px]`}
                          ></div>
                          <div
                            className={`${
                              page >= 2 ? 't-bg-[#2e97de]' : 't-bg-[#e5e7e9]'
                            } t-w-[100%] t-h-2 t-rounded t-mx-[2px]`}
                          ></div>
                          <div
                            className={`${
                              page >= 3 ? 't-bg-[#2e97de]' : 't-bg-[#e5e7e9]'
                            } t-w-[100%] t-h-2 t-rounded t-mx-[2px]`}
                          ></div>
                          <div
                            className={`${
                              page >= 4 ? 't-bg-[#2e97de]' : 't-bg-[#e5e7e9]'
                            } t-w-[100%] t-h-2 t-rounded t-mx-[2px]`}
                          ></div>
                        </div>
                      </div>
                    </div>
                    {page === 1 ? (
                      <>
                        {unauthenticated && (
                          <>
                            <Col span={24}>
                              <Form.Item
                                name="UserName"
                                label="Name"
                                colon={false}
                                rules={[roomValidation]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                name="UserEmail"
                                label="Email"
                                colon={false}
                                rules={[roomValidation]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          </>
                        )}
                        <Col span={24}>
                          <Form.Item
                            name="HoursNeeded"
                            label="How long is the room needed for?"
                            colon={false}
                            rules={[roomValidation]}
                          >
                            <Input
                              placeholder="How long is the room needed for?"
                              inputMode="numeric"
                              suffix="Hr"
                              onChange={(e) =>
                                form.setFieldsValue({
                                  HoursNeeded: e.target.value.replace(
                                    /\D/g,
                                    '',
                                  ),
                                })
                              }
                            />
                          </Form.Item>
                        </Col>
                      </>
                    ) : page === 2 ? (
                      <>
                        {(hoursNeededFilled || reservationId) && (
                          <>
                            <ReservationDetail
                              reservation={reservation}
                              isUpdate={isUpdate}
                              isSaving={onSaveLoading}
                              onSave={onSave}
                              onUpdateClick={() => setUpdate(!isUpdate)}
                            />
                            {!isClickedUpdate && (
                              <>
                                <Col span={24}>
                                  <>
                                    <SelectableCalender
                                      room={room}
                                      roomSchedule={room?.Schedule}
                                      onDateChange={onDateChange}
                                      day={dayDuration}
                                      customDurations={customDurations}
                                      onCustomDurationChange={
                                        onCustomDurationChange
                                      }
                                      selectedDate={selectedDate}
                                      setSelectedDate={setSelectedDate}
                                      isHoursInNegative={isHoursInNegative}
                                      reservationId={reservationId}
                                      alreadyReservedDurations={
                                        alreadyReservedDurations
                                      }
                                      setAlreadyReservedDurations={
                                        setAlreadyReservedDurations
                                      }
                                    />
                                    <Col span={24}>
                                      <Form.Item
                                        name="IsRecurring"
                                        // label="Recurring Reservation?"
                                        valuePropName="checked"
                                        colon={false}
                                        rules={[roomValidation]}
                                      >
                                        <div className="t-flex t-flex-row t-ml-6">
                                          <Checkbox
                                            checked={form.getFieldValue(
                                              'IsRecurring',
                                            )}
                                          />
                                          <Typography.Title className="t-text-[13px] t-font-semibold t-mx-2">
                                            Recurring Reservation?
                                          </Typography.Title>
                                        </div>
                                      </Form.Item>
                                    </Col>
                                    {isRecurring && (
                                      <Col span={24} className="t-ml-6">
                                        <Row gutter={[24, 0]}>
                                          <Col span={12}>
                                            <Form.Item
                                              name="RecurringType"
                                              label="Recurring Type"
                                              colon={false}
                                              rules={[roomValidation]}
                                            >
                                              <Select
                                                placeholder="Recurring Type"
                                                style={{ width: '100%' }}
                                                onChange={() =>
                                                  form.setFieldValue(
                                                    'RecurringFrequency',
                                                    1,
                                                  )
                                                }
                                              >
                                                {recurringTypes.map(
                                                  (item, index) => (
                                                    <Select.Option
                                                      value={item}
                                                      key={index}
                                                    >
                                                      {item}
                                                    </Select.Option>
                                                  ),
                                                )}
                                              </Select>
                                            </Form.Item>
                                          </Col>
                                          <Col span={12}>
                                            <Form.Item
                                              name="RecurringFrequency"
                                              label="# of Additional Bookings"
                                              colon={false}
                                              rules={[roomValidation]}
                                            >
                                              <Select
                                                placeholder="# of Additional Bookings"
                                                style={{ width: '100%' }}
                                              >
                                                {createFrequencyNumbers(
                                                  frequencyType,
                                                ).map((item, index) => (
                                                  <Select.Option
                                                    value={item}
                                                    key={index}
                                                  >
                                                    {item}
                                                  </Select.Option>
                                                ))}
                                              </Select>
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      </Col>
                                    )}
                                    {isConflictingAvailability && (
                                      <div className="t-text-red-500 t-mt-4 t-ml-4 t-mr-2">
                                        {`Recurring dates contain days where room is not open to book. Click below to start resolving the conflicts.`}
                                      </div>
                                    )}

                                    {isConflictingAvailability && (
                                      <div className="t-my-2 t-ml-4">
                                        <Button
                                          type="primary"
                                          size="small"
                                          onClick={() => setModal(!isModal)}
                                        >
                                          Resolve
                                        </Button>
                                      </div>
                                    )}
                                  </>

                                  {isHoursInNegative && (
                                    <Col span={24} className="t-my-3">
                                      <div className="t-text-red-500 t-font-bold">
                                        You have selected an invalid duration
                                      </div>
                                    </Col>
                                  )}
                                  {!allSlotsAvailable && (
                                    <Col span={24} className="t-my-3">
                                      <div className="t-text-red-500 t-font-bold">
                                        You Selected wrong time duration
                                      </div>
                                    </Col>
                                  )}
                                  <Col span={24}>
                                    {getTotalHoursBookedForOneDay() >
                                      room?.MaximumReservationHours && (
                                      <div className="t-text-red-500 t-font-bold">{`Maximum of ${room?.MaximumReservationHours} hours can be booked for this room in a day`}</div>
                                    )}
                                  </Col>
                                </Col>
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : page === 3 ? (
                      <>
                        <Col span={24} className="t-mb-4">
                          {questions?.length > 0 ? (
                            questions?.map((item, index) => (
                              <Form.Item
                                // name={`Q${index}`}
                                key={index}
                                initialValues={item.question}
                                rules={[
                                  {
                                    required: item.required,
                                    message: 'Question is required',
                                  },
                                ]}
                              >
                                <Typography.Title className="t-text-sm t-font-medium">
                                  {`Q${index + 1}. ${item?.question}${
                                    item?.question?.trim().endsWith('?')
                                      ? ''
                                      : '?'
                                  } ${item.required ? '*' : ''}`}
                                </Typography.Title>
                                <Input
                                  placeholder="Enter Answer Here"
                                  value={item.answer || ''}
                                  onChange={(e) =>
                                    setQuestions((previousQuestions) =>
                                      previousQuestions.map((question) =>
                                        question.id === item.id
                                          ? {
                                              ...question,
                                              answer: e.target.value,
                                            }
                                          : question,
                                      ),
                                    )
                                  }
                                />
                              </Form.Item>
                            ))
                          ) : (
                            <Typography.Title level={5}>
                              No questions available
                            </Typography.Title> // Fallback title
                          )}
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col span={24} className="t-mt-4">
                          <Title
                            level={5}
                            className="t-text-2xl t-font-semibold t-px-2"
                          >
                            Room reservation submitting details
                          </Title>
                          <div className="t-w-full">
                            <p className="t-text-base t-px-3">
                              You have reserved this room with below mentioned
                              details. Kindly confirm the reservation
                              date/dates, total hours booked, durations booked
                              and total cost for all the reservation.
                            </p>
                            <div className="t-flex t-flex-col md:t-flex-row t-space-y-2 md:t-space-y-0 t-my-4">
                              <div className="t-bg-[#f9f9fc] t-w-[100%] t-rounded">
                                <div className="t-my-3">
                                  {/* <Title
                                    level={5}
                                    className="t-text-secondary-100 t-mr-2"
                                  >
                                    {isRecurring
                                      ? 'Recurring dates'
                                      : 'Reservation Date'}
                                  </Title> */}
                                  <div className="t-flex t-flex-row t-my-3">
                                    <HiOutlineCalendar className="t-mx-4 t-text-base" />
                                    <div className="t-flex t-flex-col">
                                      {recurringDates.map((item, index) => (
                                        <div className="t-relative">
                                          <div>
                                            <Text
                                              key={index}
                                              // color={
                                              //   !item?.isAvailable
                                              //     ? 'gray'
                                              //     : 'orange'
                                              // }
                                              className="t-my-2 t-text-base"
                                            >
                                              {formateDate(item?.date)}
                                            </Text>
                                            {!item?.isAvailable && (
                                              <div className="t-absolute -t-right-1 -t-top-1">
                                                <Tooltip title="Day is not available">
                                                  <BiBlock
                                                    fontSize="1.4rem"
                                                    className="t-text-gray-800 t-cursor-pointer"
                                                  />
                                                </Tooltip>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                {isCustomDurations ? (
                                  <div className="t-flex t-flex-row t-my-3">
                                    <FaRegClock className="t-mx-4 t-mt-3 t-text-base" />
                                    <div className="t-flex t-flex-col">
                                      {customDurations?.map((item, index) => (
                                        <Text
                                          key={index}
                                          className="t-text-base t-mt-2"
                                        >
                                          {`${item?.from} - ${item?.to}`}
                                        </Text>
                                      ))}
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    {dayDuration?.durations.map((item) => (
                                      <>
                                        {durationsSelected?.includes(
                                          item.Id,
                                        ) && (
                                          <Text>{`${item?.from} - ${item?.to}`}</Text>
                                        )}
                                      </>
                                    ))}
                                  </>
                                )}
                              </div>
                            </div>
                            <Title level={5}>Price</Title>
                            <div className="t-flex t-flex-col t-bg-[#f9f9fc] t-w-[100%] t-rounded">
                              <div className="t-flex t-justify-between t-w-full">
                                <div className="t-my-4">
                                  <Text className="t-text-base t-ml-4">
                                    {/* Total Hours :{' '} */}
                                    <span
                                      className="t-mr-2"
                                      style={{ paddingRight: '4px' }}
                                    >
                                      {formatAmount(room?.PerHourRate)}
                                    </span>
                                    X
                                    <span
                                      className="t-ml-2"
                                      style={{ paddingLeft: '4px' }}
                                    >
                                      {isHoursInNegative
                                        ? 0
                                        : getTotalHoursBooked()}{' '}
                                    </span>
                                    Hours
                                  </Text>
                                </div>
                                <div className="t-my-4">
                                  <Text className="t-text-base t-mr-4">
                                    {isHoursInNegative
                                      ? formatAmount(0)
                                      : formatAmount(
                                          getTotalHoursBooked() *
                                            room?.PerHourRate,
                                        )}
                                  </Text>
                                </div>
                              </div>
                              <div className="t-flex t-justify-between t-w-full">
                                <div className="t-my-2">
                                  <Text className="t-text-base t-ml-4">
                                    {/* Total Hours :{' '} */}
                                    <span
                                      className="t-mr-2"
                                      style={{ paddingRight: '4px' }}
                                    >
                                      Processing Fee
                                    </span>
                                  </Text>
                                </div>
                                <div className="t-my-4">
                                  <Text className="t-text-base t-mr-4">
                                    {takeProcessingCharges(
                                      getTotalHoursBooked() * room?.PerHourRate,
                                      room.organization.PaymentMarkup,
                                    )}
                                  </Text>
                                </div>
                              </div>
                            </div>
                            <div className="t-flex t-flex-row t-justify-between t-my-4 t-items-center">
                              <Title level={5}>Total Payment</Title>
                              <Title level={5}>
                                {formatAmount(
                                  getTotalHoursBooked() * room?.PerHourRate +
                                    getEstimatedProcessingFee(
                                      getTotalHoursBooked() * room?.PerHourRate,
                                      room.organization.PaymentMarkup,
                                    ),
                                )}
                              </Title>
                            </div>
                          </div>
                          {room?.NeedApproval && (
                            <div className="t-font-medium t-text-base">
                              {`Your request requires approval from ${room?.OrganizationName}. We
                              will contact you as soon as your request is
                                    approved.`}
                            </div>
                          )}
                        </Col>
                      </>
                    )}

                    <Col span={24} className="t-flex t-mt-6 t-mb-4">
                      {page === 4 ? (
                        <Button
                          type="primary"
                          className="t-w-full"
                          disabled={
                            !allSlotsAvailable || isDisable || btnDisable
                          }
                          loading={loading}
                          onClick={() => form.submit()}
                        >
                          {isUpdate && reservationId
                            ? loading
                              ? 'Loading'
                              : 'Update Rental Reservation'
                            : loading
                            ? 'Loading'
                            : 'Request Rental Reservation'}
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          onClick={onClickNext}
                          className="t-w-full"
                        >
                          {/* <Button type="primary" onClick={onClickNext}> */}
                          Next
                        </Button>
                      )}
                    </Col>
                    {page === 4 && (
                      <Col span={24} className="t-text-center">
                        <Text className=" t-text-gray-400">
                          If Approval Requried, You Will Receive Link To Pay
                        </Text>
                      </Col>
                    )}
                  </Row>
                </Card>
              </Col>
            </Row>
          </Form>
        ) : (
          <RoomNotFound />
        )}
      </Skeleton>
      <Resolver
        dates={recurringDates}
        setDates={setRecurringDates}
        roomSchedule={room?.Schedule}
        visible={isModal}
        onClose={() => setModal(!isModal)}
      />
    </Card>
  ) : (
    <Confirmation
      profileWebsite={room?.organization?.Website}
      orgWebsite={room?.organization?.Website}
      orgDomain={room?.organization?.OrgDomain}
      itemType={'rental-request-sent'}
      customMessage={`Thank you for reserving ${room?.Name}. Your request will be reviewed shortly for approval. You will then receive an email with a link to pay for this reservation. You will now be redirected to ${room?.OrganizationName}'s home page.`}
    />
  )
}

export { RentalReservation }
