const config = {
  basename: '',
  stripeRedirectUri: '/stripe-connect',
  roles: {
    presenter: 'presenter',
    donor: 'donor',
    subscriber: 'subscriber',
    business: 'business',
    admin: 'platform_admin',
  },
  colors: {
    primary: '#14B1E0',
    secondary: '#0D7797',
    tertiary: '#EEEEEE',
    blue: {
      100: '#14B1E0',
    },
    gray: {
      100: '#EEEEEE',
    },
  },
  social_urls: {
    facebook: 'https://www.facebook.com/PledgeGarden/',
    instagram: 'https://www.instagram.com/PledgeGardenUSA/',
    twitter: 'https://twitter.com/PledgeGarden',
    linkledin: 'https://linkledin.com',
  },
  rowsPerPage: 6,
  itemFirstLoad: 12,
  itemPerLoad: 3,
  reportItemFirstLoad: 8,
  itemCompleteMessage: 'Yay! You have seen it all',
  splash_screen_seconds: 3,
  processing_fee_percentage: 2.5,
  defaultErrorMessage: 'Something went wrong',
  defaultSuccessMessage: 'Success',
  defaultRedirect: {
    donor: '/settings/register-organization',
    subscriber: '/home',
    admin: '/organizations',
  },
  imageMaxSizeLimitInKb: 5120,
  imageMaxSizeLimitErrorMessage: 'Image must be smaller than 5MB',
}

export default config
